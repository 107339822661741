// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

/** 公告 */
export default {
  path: '/announcement',
  component: Start,
  children: [
    {
      path: 'list',
      name: '公告列表',
      component: resolve =>
        require(['pages/message/announcement/list.vue'], resolve)
    },
    {
      path: 'details',
      name: 'announcementDetails',
      component: resolve =>
        require(['pages/message/announcement/details.vue'], resolve)
    }
  ]
}
