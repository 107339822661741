// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/hotel',
  redirect: '/home',
  component: Start,
  children: [
    {
      // 国内酒店订单列表
      path: '*',
      redirect: '/home',
    },
  ]
}
