/* eslint-disable */
/* 当前文件对应的国际化词库版本号: 7.9.2.202411151900 */
export default {
About: "約",
AboutUs: "關於我們",
AccordingTo12306TicketsCanOnlyBePurchasedAfterThePhoneNumberHasBeenVerified: "12306規定，手機號核驗通過方可購票",
AccordingToYourCompanysTravelPolicy: "根據貴司規定，您未選擇最低價艙位航班",
Account: "我的",
AccountLogin: "賬號登錄",
AccountName: "戶名",
AccountNotExist: "用户名不存在",
AccountNumber: "賬單號",
AccountNumber_1: "賬號",
AccountProtocol: "《賬戶授權協議》",
AccuratelyFillInTheAgeOfEachChildForSpecialOffers: "準確填寫每位兒童的年齡，以便我們為您查找最合適的房型及特價優惠。",
AContactAlreadyExistsWithTheSamePhoneNumber: "已存在相同手機號碼的聯絡人",
ActivityNotice: "活動通知",
ActivityNoticeDesc: "優惠活動提醒",
ActualPayment: "實付款",
ActualTotalPrice_x: "實際花費%a$",
Add: "新增",
AddAnotherTrip: "新增其他行程",
AddBusiness: "新增(因公)",
AddContact: "新增聯絡人",
AddCostCenter: "新增成本中心",
AddDay_x: "+%a$天",
AddDesignatedApprover: "新增指定審批人",
Added: "已新增",
AddFailed: "新增失敗",
AddFlight: "再新增一段航班",
AddFrequentContacts: "新增常用聯絡人",
AddFrequentTraveller: "新增為常旅客",
AddInvoiceHeader: "新增發票抬頭",
AdditionalTaxesFees: "另付稅/費",
AddManually: "手動新增",
AddPassenger: "新增乘客",
AddPersonal: "新增(因私)",
Address: "詳細地址",
AddressDistrict: "所在區域",
AddReturnTrip: "新增返程",
AddSecurityExplain: "新增保障，家人更安心",
AddSecurityExplain_1: "出行有保障，家人更安心",
AddSucceed: "新增成功",
AddTiedList: "新增至已綁定清單",
AddTransitCity: "出差途經城市皆需新增至目的地",
AddTravelCard: "新增常旅卡",
AddTraveler: "新增 +",
AdministrativeDistrict: "行政區",
AdmissionPolicy: "入住政策",
Adults: "成人",
Adults_1_x: "%a$成人",
Adults_Children_x_x: "%a$成人 %b$兒童",
Adults_x: "成人%a$",
AdultsDesc: "＞12歲",
AfterClickingGoToPay: "點擊去支付後，請打開手機微信的掃一掃，掃描二維碼支付",
AfterClickingGoToPay_1: "點擊去支付後，請打開手機支付寶的掃一掃，掃描二維碼支付",
AfterSendingTextMessage: "發送簡訊後，將收到12306發給您的六位數字驗證碼，請輸入該驗證碼",
Age_x: "%a$歲",
Agree: "同意",
AgreeAndContinue: "同意並繼續",
Agreement: "協議",
AgreementDesc: "協議描述",
AgreementHotels: "協議酒店",
AgreementHotelStarRating: "協議酒店星級",
AgreementHotelTotal: "協議酒店總金額",
AgreementHotelTotalRoomNight: "協議酒店預訂總間夜",
AgreementNight: "協議間夜",
AirChinaPassengerBaggageDomesticTransportationGeneralConditions: "國航旅客行李國內運輸總條件2017版(中文版)",
AirfareYouSearchedFor: "你搜索過的機票",
AirlineList: "航司列表",
AirlinePassengerMaintenance: "航司客規維護中",
AirLowestFareAcceptance: "最低價票預訂比例",
Airport: "機場",
AirportDropOff: "送機",
AirportDropOff_x: "%a$送機",
AirportPickup: "接機",
AirportPickup_x: "%a$接機",
AirportStation: "機場車站",
AirRouteAnalysis: "航線分析(僅經濟艙)",
AirRouteAndCarrierAnalysis: "航線及承運商分析",
AirTicketBookingInstructions: "機票預訂須知",
AirTicketConsumptionInDifferentCities: "各城市機票消費情況",
AirTickets: "機票價",
Aisle: "過道",
Alipay: "支付寶",
AliPay: "支付寶支付",
All: "全部",
AllBookings: "我的訂單",
AllCabin: "全部艙位",
AllCabins: "全部艙位",
AllCollection: "全部收藏",
AllCompanyPayment: "全部公司支付",
AllLoaded: "全部載入完畢",
AllOrdersForRecentOneMonth: "已顯示近6個月出差申請單",
AllOrdersForRecentOneYear: "已顯示全部訂單",
AllOrdersForRecentOneYear_1: "已顯示近1年全部訂單",
AllOrdersForRecentSixMonths: "已顯示近6個月全部訂單",
AllSelected: "全選",
AlreadyHave12306Account: "已有12306帳號",
AlreadySetting: "已設置",
Amount: "金額",
Amount_1: "金額(含稅)",
AmountOfBillForTheCurrentPeriod: "本期帳單金額",
AmountOfConsumption: "消費金額",
AmountPaidInThisPeriod: "本期已還金額",
AmountPayableInTheCurrentPeriod: "本期應付金額",
And: "和",
AndroidPersonalizedRecommendation: "個性化推薦",
AndroidPersonalizedRecommendationDesc: "提供個性化推薦功能，提升預訂體驗",
AndroidSettingPrivacy: "隱私設定",
Announcement: "公告",
AnnouncementInformation: "公告內容",
Any: "不限",
AnyCity: "不限城市",
Appearance: "外觀模式",
Applicant: "申請人",
ApplicantForRefund: "退票申請人",
Application: "申請",
ApplicationContent: "申請內容",
ApplicationContent_x: "申請內容: %a$",
ApplicationDate: "申請日期",
ApplicationForApproval: "申請審批",
ApplicationForBook: "申請單預訂",
ApplicationFormInformation: "申請單信息",
ApplicationInformation: "申請單信息",
ApplicationIntlFlight: "國際機票申請",
ApplicationIntlHotel: "海外飯店申請",
ApplicationNumber: "申請單號",
ApplicationToReservedNoData: "暫無待預訂的申請單",
Apply: "申 請",
ApplyForRefund: "申請退訂",
ApplyRefund: "申請退票",
ApplyTime: "申請時間",
ApplyTravel: "申請出差",
ApplyTravelConfirm: "出差申請確認",
ApplyTravelNeeds: "出差需求",
ApplyTravelSegment: "行程資訊",
ApplyTravelTime: "出差日期",
ApproachingTakeoff: "臨近起飛",
ApprovalExpired: "審批過期",
ApprovalFail: "審批失敗",
ApprovalFlow: "審批流",
ApprovalInformation: "審批資訊",
ApprovalInformationDesc: "審批狀態提醒",
ApprovalRejection: "審批拒絕",
Approvals: "審批",
Approvals_1: "我的審批",
ApprovalSetting: "審批配置",
ApprovalStatus: "審批狀態",
ApprovalSubmitted: "審批已提交",
ApprovalTemplate: "審批模板",
ApprovalTemplateDetails: "模板詳情",
ApprovalTimeoutNoticeTime: "審批超時通知時間：%a$分鐘",
ApprovalType: "審批方式",
ApprovalWhitelist: "審批白名單",
Approved: "已審批",
ApprovedCanceled: "申請取消",
ApprovedPass: "審批通過",
Approver: "審批人",
ApproversHaveBeenBound: "已綁定指定審批人",
ApproxPay: "約付",
Area: "面積",
AreYouSureToAddThisAsAFrequentTraveler: "確定將其添加為常旅客？",
AreYouSureToCancelTheOrder: "是否確認取消該訂單？",
AreYouSureToCancelUnableToSave: "取消後，該頁面信息將無法保存，是否仍要取消？",
AreYouSureToChange: "是否確認改簽？",
AreYouSureToChangeTheCurrency: "確定更改貨幣？",
AreYouSureToChangeTheLanguage: "確定更改語言？",
AreYouSureToChangeYourFlightToTheCheapestFlight: "請確認是否將您的航班變更為最低價航班？",
AreYouSureToDelete_x: "確定刪除%a$嗎？",
AreYouSureToDeleteTheApprover: "確定刪除該指定審批人？",
AreYouSureToDeleteTheCertificate: "是否刪除該證明？",
AreYouSureToDeleteTheEmployeeFromTheWhitelist: "確定從白名單中刪除該員工？",
AreYouSureToDeleteTheRC: "確定刪除該RC",
AreYouSureToDeleteTheSelectedCollection: "您確認刪除所選收藏嗎？",
AreYouSureToDeleteThisCustom: "確定刪除該自定義項目？",
AreYouSureToDeleteThisEmployeeFromTheTemplate: "確定從模板下刪除該員工？",
AreYouSureToPayForThisOrder: "您確定要支付該訂單嗎？",
AreYouSureToRemove_x: "是否刪除該%a$",
AreYouSureToRemoveItFromTheCostCenterList: "確定刪除該成本中心？",
AreYouSureToRemoveItFromTheDepartmentsList: "確定刪除該部門？",
AreYouSureToRemoveItFromTheEmployeeList: "確定將其從員工列表中刪除？",
AreYouSureToRemoveItFromTheFrequentTravellerList: "確認將其從常旅客列表中移除？",
AreYouSureToRemoveItFromTheNonEmployeeList: "確定將其從外部人員列表中刪除？",
AreYouSureToRescheduled: "是否確認改期？",
AreYouSureToSeat: "是否確認選座？",
AreYouSureToSubmitAndPayForTheOrder: "是否確認提交並支付訂單？",
AreYouSureToSubmitYourOrder: "是否確認提交訂單？",
AreYouSureToTurnOffFaceIDLogin: "確定關閉面容登錄？",
AreYouSureToTurnOffTouchIDLogin: "確定關閉指紋登錄？",
AreYouSureYouWantToDeleteThisAccount: "確認要刪除該帳號嗎？",
AreYouSureYouWantToGiveUpTheInformationChange_x: "%a$有資訊改動，確認放棄更改？",
AreYouSureYouWantToLogout: "確認要登出該帳號嗎？",
AreYouSurYouWantToDeleteThisAccount: "確定要刪除該帳號嗎？",
AreYouSurYouWantToLogout: "確定要登出該帳號嗎？",
Arrival: "到達",
ArrivalCity: "到達城市",
ArrivalCityAddress: "目的地",
ArrivalCityAddressDesc: "出差途經城市皆需添加至目的地",
ArrivalCityOptions: "可選到達城市",
ArrivalDate: "到達日期",
ArrivalEarliest: "到達從早到晚",
ArrivalLatest: "到達從晚到早",
ArrivalStation: "到達車站",
ArrivalTerminal: "到達航站樓",
ArrivalTime: "到達時段",
ArrivalTime_1: "到達時間",
ArrivalTime_2: "到達站/時間",
ArrivalTimeDepartureDate: "到達時間/離店日期",
ArrivalTimeIsLocalTime: "抵達時間為當地時間",
Arrive: "送達",
ArriveAddress: "下車地點",
Associated12306Account: "關聯12306帳號",
AtNoonToday: "今天中午",
AToB_x_x: "%a$ 至 %b$",
AttachFileUpload_x: "可再傳%a$個",
AttachFileUploadExplain_1: "·最多可以上傳10個文件，每個文件大小不超過8M",
AttachFileUploadExplain_2: "·支持的文件類型有img、word、ppt、excel、pdf",
AUD: "澳元",
Authentication: "身份驗證",
Available: "可用",
Available_x: "%a$可用",
AvailableIntegral: "可用積分",
AvailableTickets: "有票",
AverageBusinessTravelFare: "商旅平均票價",
AverageDiscount: "平均折扣",
AverageDomesticDiscount: "國內平均折扣(經濟艙)",
AverageHotelPrice: "飯店均價",
AverageMileagePrice: "里程均價(僅經濟艙)",
AverageNumberOfDaysBookedInAdvance: "平均提前預訂天數",
AveragePrice: "平均價格",
AveragePriceOfBusinessTravelAgreement: "商旅協議均價",
AveragePriceOfBusinessTravelMileage: "商旅里程均價",
AverageTicketPrice: "平均票價",
AverageTrainTicketPrice: "火車票均價",
AVerificationMessagePleaseEnterTheVerificationCodeYouReceived: "發送了一封驗證簡訊，請輸入您收到的驗證碼",
Avg: "均",
AvgTicketPriceOfMileage: "里程均價",
AwayFrom: "距離",
B: "公",
Baby: "嬰兒",
Baby_1_x: "%a$嬰兒",
Baby_x: "嬰兒%a$",
BabyDesc: "14天-2歲（不含生日當天）",
BabyNonBookable: "嬰兒不可訂",
BabyNonSupportBookable: "如需預訂嬰兒票，請聯繫您的差旅顧問",
BabyReQuery: "該航班嬰兒不可訂，如需攜帶嬰兒出行，請重新查詢可訂航班",
BabyTooSmallNonBookable: "出生不滿14天的嬰兒不可預訂",
BabyWith: "攜帶嬰兒",
BabyYearsOld: "14天-2歲",
Back: "返回",
BackFlight: "返程航班",
BackModify: "返回修改",
BackTag: "返",
BackToHome: "返回首頁",
BackToHotelList: "返回酒店列表",
BackToLogin: "返回登錄頁面",
BackWeek_x: "%a$返回",
Baggage: "行李",
BaggageAllowance_x: "行李政策：%a$",
BaggagePolicy: "行李規定",
Balance: "餘額",
Bank: "開戶行",
BankAccount: "帳戶",
BankCardInformation: "信用卡信息",
BasicInformation: "基本資訊",
BasicInformationIsIncompletePleaseEdit: "基本資訊不完整，請編輯",
BasicInformationIsIncompletePleaseEdit_x: "%a$基本資訊不完整，請編輯",
Batch: "批次管理",
BatchPass: "批次通過",
BecomeDue_x: "%a$ 到期",
BedType: "床型",
BedWide_x_x: "寬%a$ x %b$",
BeingApprovalByTheFlightCompany: "待航司審核",
BelongCity: "所屬城市",
BeOverdueByCarPleaseCheckAgain: "車輛資訊可能有變，請重新查詢",
BeOverdueByFlightPleaseCheckAgain: "航班可能有變，請重新查詢",
BeOverdueByHotelPleaseCheckAgain: "飯店資訊可能有變，請重新查詢",
BeOverdueByTrainPleaseCheckAgain: "列車可能有變，請重新查詢",
BigBed: "大床",
BillEndDate: "帳單終止日",
BillGrouping: "帳單分組",
BillingPeriod: "帳單帳期",
BillStartDate: "帳單起始日",
Bind: "立即綁定",
BindFailed: "綁定失敗",
Binding: "綁定",
BindingCTrip: "携程會員綁定",
BindingCTripDesc: "我們將使用您輸入的手機號碼綁定携程旅行個人會員，如果您還不是携程會員，恒順商旅將使用該手機號碼為您註冊携程會員。",
BindingCTripPrivacy: "閱讀並同意携程的《服務協議》和《隱私政策》",
BindingCustomItems: "綁定自訂項目",
BindingEmployees: "綁定員工",
BindingHuazhu: "華住會員綁定",
BindingHuazhuDesc: "我們將使用您的資訊與華住協議單位進行綁定，如果您還不是華住會員，恒順商旅將使用該資訊為您註冊華住會員。",
BindingHuazhuPrivacy: "閱讀並同意華住的《服務協議》和《隱私政策》",
BindingLogin: "一鍵授權綁定",
BindingMeituan: "美團會員綁定",
BindingMeituanDesc: "我們將使用您的資訊與美團協議單位進行綁定，如果您還不是美團會員，恒順商旅將使用該資訊為您註冊美團會員。",
BindingMeituanPrivacy: "閱讀並同意美團的《服務協議》和《隱私政策》",
BindingStatus: "綁定狀態",
BindMailbox: "綁定郵箱",
BindMembershioFor_X: "綁定%a$會員卡",
BindMembersToEnjoyLowerPrices: "綁定會員立享更低價格",
BindPerson_x: "已綁定%a$人",
BindSuccessfully: "綁定成功",
BlankImport: "空白導單",
Book: "訂",
Book_1: "訂票",
Book_2: "立即預訂",
Book_3: "預 訂",
BookAllStaff: "為全部員工預訂",
BookCar: "預訂車輛",
BookDesignated_x: "為指定%a$預訂",
BookDesignatedDepart: "為指定部門預訂",
BookDesignatedStaff: "為指定員工預訂",
BookDomesticFlights: "預訂國內機票",
BookDomesticHotel: "預訂國內飯店",
Booker: "預訂人",
BookFinished: "訂完",
BookFlight: "預訂機票",
BookHotel: "預訂飯店",
BookInAdvance: "提前預訂",
Booking: "預訂",
BookingDaysInAdvance: "提前預訂天數",
BookingInformation: "預訂資訊",
BookingInstructionsAndInstructionsForReturning: "預訂須知和退改說明",
BookingLevel: "預訂級別",
BookingMail: "預訂郵件",
BookingMethods: "預訂方式",
BookingRange: "預訂範圍",
BookingSMS: "預訂簡訊",
BookingSource: "預訂來源",
BookingSucceed: "預訂成功",
BookingTheBus: "預訂汽車票",
BookingTheCar: "預訂用車",
BookingTheMeals: "預訂用餐",
BookInternationalFlights: "預訂國際機票",
BookInternationalHotel: "預訂海外飯店",
BookOnlySelf: "僅限幫本人預訂",
BookPermissions: "預訂權限",
BookTheLowestLogicalFare: "預訂最低價",
BookTrain: "預訂火車票",
BookType: "預訂類型",
Bound: "已綁定",
Bound_1: "已綁員工",
BoundCustomItems: "已綁自訂項目",
BoundEmployees: "已綁員工",
Brand: "品牌",
Breakfast: "含早",
Breakfast_1: "早餐",
BrowserOpens: "瀏覽器打開",
Budget: "預算",
Budget_1_x: "%a$預算",
Budget_x: "預算%a$",
BudgetAmount: "預算金額",
BulkRejection: "批量拒絕",
Business: "因公",
Business_x: "%a$(因公)",
BusinessCenter: "商務中心",
BusinessClass: "公務艙",
BusinessConsultant: "業務顧問",
BusinessDistrict: "商業區",
BusinessDistrictInfrastructureBrandEtc: "商圈/基礎設施/品牌 等",
BusinessTravel: "商旅",
BusinessTravelScale: "企業差旅規模(月度)",
BusinessTrip: "因公出行",
BusinessType: "業務類型",
BusinessType_1: "業務",
BusinessUnit: "BusinessUnit",
BusOrderConfirmation: "汽車票訂單確認",
BusOrderDetails: "汽車票詳情",
BusRank: "汽車票差標",
BusRefundOrderConfirm: "汽車票退票訂單確認",
BusStation: "汽車站",
BusTicket: "汽車票",
BusTicketOrder: "汽車票訂單",
By: "經",
By_x: "經 %a$",
ByMail: "通過郵箱",
ByPhoneNumber: "通過手機號碼",
Cabin: "艙等",
CabinInformationHasBeenUpdated: "艙位資訊已更新，訂單部分內容可能發生變更，請重新填寫訂單以確保準確性",
CabinLevel: "艙位等級",
CabinType: "艙位類型",
CAD: "加拿大元",
Calendar: "日曆",
CallCar: "叫車",
CallingForYou: "正在為你呼叫...",
CallPhone: "打電話",
CallThePolice: "110報警",
Camera: "拍攝",
CanAccommodateUpToXPeople_x: "每間最多住%a$人",
CanAccommodateXPeople_x: "每間可住%a$人",
Cancel: "取消",
Cancel_1: "暫不升級",
Cancel_2: "取消管理",
CancelAnyway: "仍要取消",
Canceled: "已取消",
CancelFailed: "取消失敗",
CancelForFree: "免費取消",
CancelInformation: "取消資訊",
CancelInquiry: "取消需求單",
Cancelling: "正在取消",
CancelOrder: "取消訂單",
CancelReason: "取消原因",
CancelRefund: "取消退票",
CancelRules: "取消規則",
CancelSucceed: "取消成功",
CannotBeCancelled: "不可取消",
CanNotSupportedFileType: "暫不支持該文件類型，請重新選擇",
CanOnlySelectUpToXGuest_x: "每間房最多只能選擇%a$個入住人",
CanOnlySelectUpToXpassengers_x_x: "最多只能選擇%a$個%b$",
CanPeopleStaying_x: "可住%a$人",
Car: "用車",
CarAirport: "機場接送",
CarApplication: "用車申請",
CarbonEmission: "碳排放量",
CarbonOffset: "碳補償(種植棵樹)",
CardholderInformation: "持卡人信息",
CarDistance_x: "行駛了%a$公里",
CardType: "卡類型",
CarDuration_x: "共花費%a$分鐘",
CarFare: "用車票價",
CarOrder: "用車訂單",
CarOrderConfirmation: "用車訂單確認",
CarPlatform: "用車平台",
CarRank: "用車差標",
Carrier: "承運人",
Carrier_1: "承運商",
CarrierAnalysis: "承運商分析",
CarriersItineraries: "承運商&航線",
CarScene: "用車場景",
CarServiceTime: "用車時間",
CarTrain: "火車站接送",
CarType: "用車類型",
CellphoneNumber: "手機號碼",
CertificateInformation: "證件資訊",
Change: "改簽",
Change_1: "改",
Change_2: "修改航班",
Change_3: "切換12306賬號",
ChangeDetails: "改期明細",
ChangeFailed_1: "修改失敗",
ChangeFee: "改簽費",
ChangeFlight: "修改航班",
ChangeFlightSchedule: "改期班機",
ChangeInstructions: "改簽規則",
ChangeMobileNumber: "更換手機號碼",
ChangeMobileNumber_1: "更 換 手 機 號",
ChangePassword: "修改密碼",
ChangePolicy: "更改規定",
ChangeRate: "改簽率",
ChangeSchedule: "改簽行程",
ChangeSegment: "更改行程",
ChangeStation: "變更到站",
ChangeSucceed: "改簽成功",
ChangeSuccessFailed: "改簽失敗",
ChangeSuccessfully: "改簽成功",
ChangeTheNumberOfPeople_x: "您選擇的乘機人有變化，為您調整為%a$，如不滿足您的需要，您可以自行修改乘機人",
ChangeTheNumberOfRoomsAndPeople_x_x: "您選擇的入住人數有變化，為您調整為%a$間%b$人，如不滿足您的需要，您可以自行修改房間入住人數",
ChangeTo_x: "更改為%a$",
ChangeYourFlightAndContinueDeparting: "換個班機，繼續出發~",
Changing: "改簽中",
Charge: "收費",
ChargebackDateTime: "退單申請時間",
ChargebackDetails: "退單詳情",
ChargebackNumber: "退單號",
ChargebackNumber_x: "退單號%a$",
ChargebackRoom: "取消間數",
ChargebackTime: "取消日期",
ChargeByAirlineRefundRules: "按航空公司退改規則收費",
ChargedAccordingToTheCorrespondingAirlineTicketRegulations: "根據相應航空公司客票規定(NFD)收取",
CheapestFlight: "最低價航班",
CheapFlights: "特價機票",
Check: "核驗",
CheckerInformation: "入住人資訊",
CheckIn: "入住",
CheckIn_x: "%a$入住",
CheckInCheckOut_x_x_x: "%a$ 入住，%b$ 離店，%c$晚",
CheckInCity: "入住城市",
CheckInCounterIsClosedNotBookedYet: "值機櫃台已關閉，暫不預訂",
CheckInDate: "入住日期",
CheckInformation: "核對資訊",
CheckingForTheLatestPriceForYou: "正在為您查詢最新價格，請稍後...",
CheckInHotel: "入住飯店",
CheckInRules: "入住規則",
CheckInSeat: "值機選座",
CheckInToCheckoutNight_x_x_x: "%a$ 至 %b$ %c$晚",
CheckInToCheckoutNight_x_x_x_x_x: "%a$ %b$ 入住，%c$ %d$ 離店，%e$晚",
CheckInToCheckoutNightAndRoom_x_x_x_x: "%a$ 至 %b$ %c$晚/%d$間",
CheckInToCheckoutNightAndRoomAndRoom_x_x_x_x_x: "%a$ 至 %b$ · %c$晚%d$间 · %e$",
CheckOrder: "檢視訂單",
CheckOut: "離店",
CheckOut_x: "%a$離店",
CheckOutDate: "離店日期",
CheckTheDifference: "點擊查看差標詳情",
CHF: "瑞士法郎",
ChildOfAge: "兒童年齡",
Children: "兒童",
Children_1_x: "%a$兒童",
Children_x: "兒童%a$",
ChildrenAndBabyExplain: "兒童嬰兒預訂說明",
ChildrenDesc: "2-12歲（不含生日當天）",
ChildrenNonBookable: "兒童不可訂",
ChildrenNonSupportBookable: "如需預訂兒童票，請聯繫您的差旅顧問",
ChildrenReQuery: "該航班兒童不可訂，如需攜帶兒童出行，請重新查詢可訂航班",
ChildrenWith: "帶兒童",
ChildrenYearsOld: "2-12歲",
China: "國內",
China_1: "國內(含港澳台) 目的地",
China_3: "中國",
ChinaRegion: "國內(含港澳台)",
ChinaSouthernAirlinesNewRegulations: "南航新規提醒：乘客可通過南航APP和微信退改本人機票，並可直接獲取退票款。如乘客私自到南航退款，我司將仍按原訂機票費用向貴司全額收取。提醒貴司做好監管，否則存在風險。",
ChineseName: "中文姓名",
Choose: "選擇",
Choose_x: "選擇%a$",
ChooseAirport: "選擇機場",
ChooseAtLeastXPeople_x: "至少需要選擇%a$位入住人",
ChooseAtMostContact_x: "最多只能選擇%a$名聯繫人",
ChooseBU: "選擇BU",
ChoosePhotos: "選擇照片",
ChooseTheLowestPriceToSave: "選擇最低價節省",
ChooseTheReason: "選擇原因",
ChooseTrainStation: "選擇火車站",
ChooseUpToXPeople_x: "最多只能選擇%a$位入住人",
CI: "入住",
CICO: "入離日期",
City: "城市",
City_1: "按起降城市",
CityAnalysis: "城市分析",
ClassAnalysis: "艙位分析",
ClassHotel: "型酒店",
ClassRoomTypeSeat: "艙等/房型/座席",
Clear: "清空",
ClearCache: "清除快取",
ClearHistory: "清空歷史記錄",
ClickLogin: "點擊登入",
ClickNextToIndicateThatYouHaveAcceptedAndAreAwareOfTheRefundPolicyForThisTicket: "點擊下一步表示已接受並知曉此機票的退改簽規則",
ClickRefresh: "點擊重新整理",
ClickShrink: "點擊收起",
ClickToResend: "點擊重新發送",
ClickToSetTheAddress: "點擊設置地址",
ClickToView: "點擊查看",
Close: "關閉",
Closed: "已關閉",
CloudBill: "雲帳單",
CloudQuickPay: "雲閃付",
CN: "中",
CNYStart_x: "%a$起",
CO: "離店",
CO2Emissions: "碳排放",
CodeShare: "共享",
ComfortHotel: "舒適型酒店",
ComfortHotel_1: "舒適",
CommentsDetails: "評論詳情",
CommodityDetails: "商品詳情",
CommonOperations: "常用操作",
Company: "公司",
CompanyAndPersonalPayment_1_x_x: "公司支付%a$，個人支付%b$",
CompanyAndPersonalPayment_x_x: "公司%a$ 個人%b$",
CompanyInfo: "單位資訊",
CompanyInvoiceHeader: "公司抬頭",
CompanyName: "企業名稱",
CompanyPayment: "公司支付",
CompanyPaymentPrepay: "公司支付(預付款)",
CompanyTel: "公司聯絡電話",
ComplaintsSuggestions: "意見回饋",
Completed: "已完成支付",
CompleteVerification: "完成核驗",
Confirm: "確定",
Confirm_1: "確認",
Confirm_2: "選好了",
Confirm_3: "確 定",
ConfirmationOrder: "確認單",
ConfirmDeparture: "確定離開",
Confirmed: "已確認",
ConfirmedCallCar: "已確認，立即叫車",
ConfirmedCheckInTimeAtTheCounterCanBeBooked: "已到櫃台確認值機時間，繼續預訂",
ConfirmImmediately: "立即確認",
Confirming: "確認中",
ConfirmMail: "確認郵件",
ConfirmPay: "確認支付",
ConfirmRules: "確認規則",
ConfirmSelectedTraveler_x: "確認選擇·%a$人",
ConfirmSelection: "確認選擇",
ConfirmSMS: "確認簡訊",
ConfirmTheChanges: "確認修改",
ConfirmTheChanges_1: "確 認 修 改",
ConfirmTheNewPassword: "確認新密碼",
ConfirmTheRemovalOfTheirDocuments: "確認將其證件移除?",
ConfirmToResendApprovalInformation: "是否確認重發審批資訊?",
ConfirmUnbinding: "確認解綁？",
ConfirmUpdate: "確認更新",
Consignee: "收貨人",
Consultant: "專屬顧問",
ConsumptionOfCarsInDifferentCities: "各城市用車消費情況",
ConsumptionOfTrainTicketsInEachCity: "各城市火車票消費情況",
ContactCustomerService: "聯繫客服",
ContactDetails: "連絡人詳情",
ContactDriver: "聯繫司機",
ContactEmailFormatIsIncorrect_x: "聯絡人%a$的郵箱格式不正確",
ContactHotel: "聯繫飯店",
ContactInformation: "聯絡人資訊",
ContactInformationOfExclusiveConsultant_x: "專屬顧問%a$的聯絡方式",
ContactMobileFormatIsIncorrect_x: "聯絡人%a$的手機號格式不正確",
ContactName: "聯絡人姓名",
ContactNumber: "聯絡電話",
ContactNumberColon: "聯絡電話：",
ContactPhoneNumber: "聯絡人手機號碼",
Contacts: "聯絡人",
Contacts_x: "聯絡人%a$",
ContactTheEmailNotificationHasBeenTurnedOn_x: "連絡人%a$已開啟郵件通知功能，郵箱地址不能為空！",
Content: "內容",
ContentIsEmpty: "內容為空",
Continue: "繼續",
ContinueBooking: "繼續預訂",
ContinueBooking_1: "繼 續 預 訂",
ContinueBookingWithoutRescheduling: "不改期，繼續預訂",
ContinuetoBook: "繼續預訂原航班",
Copy: "份",
Copy_1: "複製",
CopyInvitationLink: "複製邀請連結",
CopyLink: "複製pdf連結",
CopySuccess: "已複製到剪貼簿",
CopyTheLink: "複製連結",
CopyToClipboard: "複製到剪貼簿",
CorporateTourism: "企業旅遊",
CostCenter: "成本中心",
CostCenterName: "成本中心名稱",
CreationDate: "建立日期",
CreationTime: "建立時間",
Credential: "證件",
CreditCard: "信用卡",
CreditCardGuarantee: "信用卡擔保",
CreditGuarantee: "信用擔保",
Currency: "貨幣",
CurrencyExplain_x: "貨幣：%a$ 每位成人票價",
CurrencySelectedIncorrectly: "選擇幣別有誤，請重新選擇",
CurrencySetting: "貨幣設置",
CurrentAdvancePayment: "當前預付款",
CurrentCityNoCarService: "當前城市該時段暫未提供服務",
CurrentIntergral: "當前積分",
CurrentLxd: "當前可用旅行豆",
CurrentlyBusiness: "當前只開通了因公查詢",
CurrentlyOnlineInsuranceOnlySupportsTheFirstSegment: "目前線上保險僅支援購買第一程",
CurrentlyOnlyDomesticAirTickets: "目前只支援國內機票。違反機票差旅標準，需要選擇的原因",
CurrentlyPrivate: "當前只開通了因私查詢",
CurrentMobile: "當前手機號碼：",
CurrentOrderGeneratedOrder_x: "當前下單生成了%a$個訂單",
CurrentOrderGeneratedOrderYouNeedToPayInTurn_x: "當前下單生成了%a$個訂單，您需要依次支付",
CurrentPageDownload: "目前頁面下載",
CurrentPassword: "目前密碼",
CurrentPosition: "目前位置(僅供參考)",
CurrentVersion: "目前版本",
CustomerChoosesThePrice: "客戶選擇價格",
CustomerExperienceFirst: "客戶體驗第一",
CustomerServiceSupport: "客戶服務支援",
CustomItems: "自訂項目",
CustomizationsBound: "已綁定自訂項目",
CustomUseLxd: "自訂使用旅行豆數量",
Dark: "深色模式",
DataUpTo: "資料更新至",
Date: "日期",
DateIsTheLocalDateOfDepartureCity: "您選擇的是出發城市的當前日期",
DateOfBirth: "出生日期",
DateOptions: "可選日期",
Days_1_x: "+%a$天",
Days_x: "%a$天",
DeductionLxd: "抵扣",
DeductionRequired: "需扣款項",
Default: "預設",
Delete: "刪除",
Delete_1: "移除",
DeleteSuccessFailed: "刪除失敗",
DeleteSuccessful: "刪除成功",
DeliveryAddress: "運送地址",
DeliveryAfterSuccessfulBooking: "預訂成功後送",
DeliveryInfo: "配送資訊",
DeliveryMethod: "配送方式",
DepartingColon: "去:",
Department: "部門",
DepartmentName: "部門名稱",
Departure: "出發",
Departure_1: "上車地點",
DepartureCity: "出發城市",
DepartureCityAddress: "出發地",
DepartureCityAndArrivalCityCannotBeSame: "出發城市和到達城市不能相同",
DepartureCityAndArrivalCityCannotBeSame_x: "第%a$程出發城市和到達城市不能相同",
DepartureCityOptions: "可選出發城市",
DepartureDate: "出發日期",
DepartureDateLocation: "出發日期(當地時間)",
DepartureDateOptions: "可選出發日期",
DepartureEarliest: "從早到晚",
DepartureEarliest_1: "出發從早到晚",
DepartureLatest: "從晚到早",
DepartureLatest_1: "出發從晚到早",
DepartureStation: "出發車站",
DepartureTerminal: "出發航站樓",
DepartureTime: "出發時段",
DepartureTime_1: "出發站/時間",
DepartureTime_2: "起飛時間",
DepartureTime_3: "起飛時間段",
DepartureTimeCheckInDate: "出發時間/入住日期",
DepartureWeek_x: "%a$出發",
DesignateApprover: "指定審批人",
Destination: "目的地",
DestinationOptions: "可選目的地",
Detail: "明細",
Details: "詳情",
Details_1: "查看詳情",
DetailsOfThisMonth: "本月明細",
DidiDriverIsComing: "司機正在趕來",
DifferentFromTheDepartingAirport: "與去程出發機場不同",
DifferentFromTheOriginalScheduledArriveAirport: "與原訂行程到達機場不同",
DifferentFromTheOriginalScheduledDepartureAirport: "與原訂行程出發機場不同",
DifferentFromTheOriginalScheduledDepartureAndrArriveAirport: "與原訂行程出發機場和到達機場不同",
DiffPrice: "差價",
Dimension: "分析維度",
Dimension_1: "多維度分析",
Diner: "用餐人",
DinerDetail: "用餐人詳情",
DinerInfomation: "用餐人資訊",
DinerName: "用餐人姓名",
Dingtalk: "釘釘",
DingTalkByAccount: "釘釘帳號",
DingTalkByUser: "釘釘員工綁定",
Direct: "直達",
DirectFlightFirst: "直飛優先",
DirectionsForUse: "使用說明",
Disagree: "不同意",
DiscountDistribution: "折扣分布",
DiscountInterval: "折扣區間",
DistanceCenter_x_x: "距離市中心%a$·%b$",
DistanceFromYou_x: "距離您%a$",
DistanceLowToHigh: "直線距離近-遠",
DistanceNearToFar: "按距離從近到遠",
DistanceToDestination_x: "距離目的地%a$",
DocumentInformationVerificationFailed: "證件資訊核驗未通過",
DocumentSignatory: "證件簽署國",
DomAverageDiscount: "國內平均折扣(僅經濟艙)",
DomesticFlights: "國內機票",
DomesticFlightsApplication: "國內機票申請",
DomesticFlightsOrderConfirmation: "國內機票訂單確認",
DomesticHotel: "國內酒店",
DomesticHotelApplication: "國內酒店申請",
DomesticHotelOrderConfirmation: "國內酒店訂單確認",
DomesticHotelOrders: "國內酒店訂單",
DomesticServicePhone: "境內服務熱線",
DomesticTicketChange: "國內機票改期",
DomesticTicketChangeApplication: "國內機票改期申請",
DomesticTicketOrder: "國內機票訂單",
DomesticTicketRefundApplication: "國內機票退票申請",
DomFullFareTicketRate: "國內全價票比例",
DoNotChange: "不可更改",
DoNotChangeBack: "不可退改",
DoNotUse: "暫不使用",
DoNotUseLxd: "暫不使用旅行豆",
DoubleSIMDeviceTip: "當前設備為雙卡設備，請注意選擇號碼發送短信",
Download: "下載",
DownloadAll: "全部下載",
Downloaded: "已下載",
DownloadFailed: "下載失敗",
Downloading_x: "下載中...%a$%",
DownloadPolicy: "下載保單",
DownloadSchedule: "下載明細表",
DownloadTheQRcode: "下載二維碼",
DowntownCenter: "市中心",
DoYouWantToLeaveHumanCustomerService: "是否離開人工客服",
DrillMultipleChoice: "鑽級(可多選)",
DriverArrival: "司機已到達",
DropDownToViewHistory: "下拉查看歷史訊息",
Duration: "行程用時",
DutyParagraph: "公司稅號",
DwellTime: "停留時間",
Dysfunction: "功能異常",
E: "終",
EachRoomNeedsToFillIn_x: "每間需填寫%a$人",
EarlyThisMorning: "今天凌晨",
EarlytoLate: "起飛時間 早-晚",
Economic: "經濟",
EconomicHotel: "經濟型酒店",
EconomicHotel_1: "經濟",
Economy: "經濟",
EconomyClass: "經濟艙",
EconomyClassDiscountBelow_x: "經濟艙 (%a$折及以下)",
Edit: "編輯",
Edit_x: "編輯%a$",
EditContact: "修改聯絡人",
EditContactFailed: "修改聯絡人失敗",
EditCostCenter: "編輯成本中心",
EditDepartment: "編輯部門",
EditFrequentContact: "編輯常用聯絡人",
EditInvoiceHeader: "編輯發票抬頭",
EditRC: "編輯RC",
Effective: "已生效",
EffectiveDate: "證件有效期",
ElectronicInvoice: "電子發票",
Email: "郵箱",
EmailAddress: "郵箱地址",
EmailApproval: "郵件審批",
EmailFormatIsIncorrect: "郵件格式不正確！",
EmailFormatIsIncorrect_x: "%a$的郵箱格式不正確",
EmergencyContactMobile: "緊急聯絡人號碼",
EmergencyContactName: "緊急聯絡人姓名",
EmergencyContactNumber: "緊急聯絡電話",
EmergencyHelp: "緊急求助",
Emission: "排放量(kg)",
EmployeeDetails: "員工詳情",
EmployeeInformation: "人員信息",
EmployeeManagement: "員工管理",
EmployeeNumber: "員工數",
EmployeesCanApply: "員工訪問連結可申請加入團隊",
EmployeesCcanScan: "員工可掃描 QR 碼加入團隊",
EmptyFileCannotBeUploaded: "無法上傳空文件，請重新選擇",
EN: "英",
EnableFingerprintLogin: "是否開通指紋登入",
End: "結束",
EndDate: "結束日期",
EnglishName: "英文姓名",
EnquiryOrderNumber: "需求單號",
EnterANewPassword: "輸入新密碼",
EnterflightNoMU5166: "請輸入航班號，如MU5166",
EnteringKefu: "正在連接人工客服",
EnterMembershipNumber: "請輸入會員卡號",
EnterNewMobile: "請輸入新的手機號碼",
EnterPhoneNumber: "請輸入手機號碼",
EnterpriseCooperation: "企業合作",
EnterTheLastFourDigitsOfYourID: "證件號後四位",
EnterVerificationCode: "請輸入驗證碼",
EnterVerificationCode_1: "輸入驗證碼",
EnterVerificationCodeForThe12306: "請輸入12306回覆的驗證碼",
EnterYourNewPasswordAgain: "再次輸入新密碼",
Estimate: "預估",
EstimateMeteredBilling: "預估(打表計費，以實際為準)",
ETA: "預計到店",
Etc: "...等航司",
EUR: "歐元",
EvaluateKefu: "請對本次服務做出評價",
ExceedingOrderAmount: "超出滿減限制",
Exchange: "兌換",
Exchange_1: "立即兌換",
ExchangeSerialNumber: "兌換流水號",
ExchangeSuccessful: "兌換成功",
ExcludingFullPriceAverageDiscount: "不含全價平均折扣",
ExclusiveBrandPrivileges: "尊享品牌特供權益",
ExclusiveConsultant_x: "專屬顧問%a$",
ExistingBusinessTravelAccount: "已是恒順商旅簽約企業",
ExistingStaff: "已有員工",
ExistingStaff_x: "已有員工(%a$)",
Exit: "安全出口",
ExitWarning: "退出將解除微信與此帳號的綁定，解除綁定將不能接收此帳號微信消息通知，確定要退出嗎?",
ExpandMore: "展開更多",
ExpectToAt: "預計",
Expired: "已過期",
ExportOrder: "下載訂單明細",
Express: "快遞",
ExpressDelivery: "快遞配送",
Ext: "分機號",
ExtendedInformation: "擴展資訊",
Face: "人臉核驗",
FaceIDIsNotEnabled: "當前設備沒有開通面容ID",
FaceIDIsOnlyValidForTheMachine: "面容ID僅針對本機有效",
FaceIDLogin: "面容登入",
FacilitiesDetails: "設施/詳情",
FacilityServices: "設施服務",
FailedToAccessTheCamera: "訪問攝像頭失敗",
FailedToChangePassword: "修改密碼失敗",
FailedToGetPleaseTryAgainLater: "獲取失敗，請稍候再試",
FailedToResetPassword: "重置密碼失敗",
FalseAlarmAccountability: "謊報警情，依法追責",
FalseAlarmAccountabilityDesc_1: "一鍵報警接通平台客服，如遇危害人身及財產安全的緊急情況，請直接撥打110。",
FalseAlarmAccountabilityDesc_2: "謊報警情將可能被處以五日以上十日以下拘留",
FeaturedIntroduction: "特色介紹",
Feedback: "意見回饋",
Feishu: "飛書",
FeishuByAccount: "飛書帳號",
FeishuByUser: "飛書員工綁定",
Female: "女",
File: "文件",
FileDownloadFailed: "檔案獲取失敗",
FileSizeLimit_x: "文件僅支援%a$Mb以下大小",
FillInAccordingToTheActualNumber: "按照實際人數填寫",
FillingGuide: "填寫指南",
FillInTheReason: "填寫原因",
FillInYourBoundEmailAddress: "填寫您綁定的郵箱地址",
Filter: "篩選條件",
Filter_1: "高級篩選",
Filters: "篩選",
FindANewQueryQuote: "找到新的查詢報價",
FingerprintDoItAgain: "驗證失敗，再試一次",
FingerprintTouch: "輕觸指紋感應器",
FingerprintVerification: "驗證指紋",
FingerprintVerificationTooManyTimes: "指紋驗證次數過多，請稍後再試",
Finish: "完成",
Finished: "已完成",
First: "第",
FirstBusinessClass: "頭等/公務艙",
FirstClass: "頭等艙",
FirstClassSeat: "一等座",
FirstNameFormatIsIncorrect: "英文名格式不正確！",
FirstStep: "第一步",
FixedPrice: "一口價",
Flight_x: "%a$班機",
FlightBookPermission: "機票預訂權限",
FlightCancellationDelayForMoreThan: "航班取消/延誤30分鐘以上，以航空公司審核為準",
FlightConfirmationWarn: "用於辦理簽證和出入境審查，可列印確認單並在出行時隨身攜帶",
FlightDate: "航班日期",
FlightDeparturesAreLocalTime: "航班起降均為當地時間",
FlightDetails: "航班詳情",
FlightDynamicsAreForReferenceOnly: "航班動態僅供參考，請以航司發布為準",
FlightIndex_x: "第%a$程",
FlightInformation: "航班動態",
FlightInformationDesc: "航班變更等行程提醒",
FlightNo: "航班號",
FlightNo_x: "航班號%a$",
FlightNumber: "按航班號",
FlightNumberHotelTrainNumber: "航班號/飯店/車次",
FlightPreference: "航班偏好",
FlightPricesChangeFrequently: "航班價格變動頻繁，請儘快完成支付以免耽誤您的出行",
FlightRank: "機票差標",
FlightRefundOrderConfirm: "機票退票訂單確認",
FlightRefundTrip: "退票行程",
FlightRescheduleOrderConfirm: "申請改期訂單確認",
FlightReschedulingOrRefundTips: "溫馨提示：已辦理值機手續的旅客需要先行取消值機，方可操作改期、退票。",
FlightSeatInfoArrow: "選座資訊 >",
FlightsPassenger: "乘客",
FlightsRefunds: "國內機票退票",
FlightTicket: "機票",
FlightType: "航班類型",
Floor: "層",
Floor_1: "樓層",
Floor_x: "%a$層",
FlyingTime_x: "飛行 %a$",
FollowTheSystem: "跟隨系統",
Foods: "餐品",
FoodTotalPrice: "商品總價",
ForgetPassword: "忘記密碼",
ForgetPassword_1: "忘記密碼?",
FormatIsIncorrect: "格式不正確",
Free: "快遞 免郵",
FreeBoardband: "免費寬帶",
FreeParking: "免費停車場",
FreePickUpService: "免費接機服務",
FreeWiFi: "免費WiFi",
FrequentContacts: "常用聯絡人",
FrequentContactsManagement: "常用聯絡人管理",
FrequentTraveler: "常旅客",
FrequentTravelerCardInformation: "常旅卡資訊",
FrequentTravelerManagement: "常旅客管理",
FriendsareWaiting: "的小伙伴在恒順商旅等你，用這個專屬連結加入我們吧！",
From: "起",
Fuel: "燃油",
FuelFee: "燃油費",
FullPrice: "全價",
FullyBooked: "已訂完",
FullyCallingTheVehicle: "正在全力呼叫車輛",
GBP: "英鎊",
Gender: "性別",
GeneralAmenities: "服務設施",
GeneralCarUsage: "用車總概",
GeneralConditionsOfTransportation: "運輸總條件",
GeneralTrainTicket: "火車票總概",
GetSMS: "獲取簡訊",
GettingBoardingLocation: "正在獲取上車地點",
GivenNames: "名(拼音)",
GivenNamesEg: "如：MEIMEI",
GoAndReturn: "去/返",
GoApprovals: "去審批",
GoBook: "去預訂",
GoEnquiry: "去詢價",
GoFlight: "去程航班",
Going: "去程",
GoingAndReturnTicket_x_x: "%a$(去程),%b$(返程)",
GoingDate: "去程日期",
GoingTicket_x: "%a$(去程)",
GoingTo: "將要去",
GoTag: "去",
GoToBind: "去綁定",
GoToChange: "去改期",
GoToHotel: "去飯店",
GoToMixedPay_x: "超標支付%a$",
GoToPay: "去支付",
GoToPay_1_x: "支付%a$",
GoToPay_x: "去支付%a$",
GoToQuery: "前往查詢頁面",
GoToRefund: "去退票",
GoToTheStoreBeforeOClock_x: "%a$之前到店",
Guarantee: "擔保",
Guarantee_1: "需擔保",
GuessYouWantToFindIt: "猜你想問",
GuestRoom_x: "房间%a$",
Guests: "入住人數",
Gym: "健身房",
HasProblem: "支付遇到問題",
HasQuitHumanCustomerService: "已退出人工客服",
HaveAgreement: "有協議價",
HaveQuestions: "有疑問？您可以點擊下方按鈕進行咨詢哦~",
HaveRefund: "有退票",
HaveRescheduled: "有改期",
HelpCenter: "幫助中心",
HengshunTravelAPP: "恒順商旅APP",
HightoLow: "價格 高-低",
History: "歷史記錄",
HistoryOrHot: "歷史/熱門",
HistoryOrLocation: "當前/歷史",
HistoryRecord: "歷史記錄(近1年)",
HKD: "港幣",
HoldToTalk: "按住 說話",
Home: "首頁",
Home_1: "家",
Homsom: "我的恒順",
HOMSOM: "恒順商旅",
HomsomEnterpriseCooperation: "恒順商旅企業合作",
HomsomEnterpriseCooperationDesc: "如貴司已是恒順商旅簽約企業，請聯繫您的客戶經理獲取帳號，請勿重複註冊",
HomsomFWXY: "《服務協議》",
HomsomLoginPrivacyInfo: "已閱讀並同意《服務協議》和《隱私政策》",
HomsomLoginPrivacyTitle: "請閱讀並同意以下協議",
HomsomLoginPrivacyValue: "為了保障您的個人資訊安全，使用登錄功能需要您先閱讀並同意《服務協議》和《隱私政策》",
HomsomLxd: "恒顺旅行豆",
HomsomPrivacyInfo: "《服務協議》和《隱私政策》",
HOMSOMScanningCodeLoginConfirmation: "恒順商旅網掃碼登錄確認",
HomsomServiceFee: "恒順服務費",
HomsomServiceFeeByChange: "恒順改期服務費",
HomsomServiceFeeByIssue: "恒顺出票服務費",
HomsomSuccessDesc: "恒順商旅已為您完成本次預訂服務",
HomsomUpdateToExperience: "恒順商旅又有更新了，快來搶先體驗吧",
HomsomYSZC: "《隱私政策》",
Hot: "熱門",
Hot_1: "熱門兌換",
HotChina: "國內熱門",
Hotel: "飯店",
Hotel_x: "%a$飯店",
HotelAddress: "飯店地址",
HotelBedNeeds: "床型要求",
HotelBookPermission: "飯店預訂權限",
HotelBookWarn: "請您在提交訂單前仔細閱讀《恒順商旅酒店預訂條款》《個人信息授權聲明》《供應商預訂條款》",
HotelBookWarn_1: "《恒順商旅酒店預訂條款》《個人信息授權聲明》《供應商預訂條款》",
HotelChargeback: "飯店退單",
HotelCheckInInfo: "入住信息",
HotelCheckInPost_x: "%a$後入住",
HotelCheckOutBefore_x: "%a$前退房",
HotelConfirmEmailChineseOrEnglish: "·中/英版確認郵件（中/英版入住憑證pdf附件）",
HotelConfirmEmailChineseOrEnglishFlight: "·中/英版確認郵件（中/英版機票確認單pdf附件）",
HotelConfirmNumber: "飯店確認號",
HotelConfirmSMSChineseOrEnglish: "·中/英版確認簡訊",
HotelConsumptionInEachCity: "各城市飯店消費情況",
HotelDetails: "飯店詳情",
HotelFeature: "酒店特色",
HotelGeneral: "飯店總概",
HotelGroupMembership: "飯店集團",
HotelGroupMembershipCard: "集團會員卡",
HotelGroupMembershipCard_1: "飯店集團會員卡",
HotelGroupMembershipCardBind: "綁定會員",
HotelGroupMembershipCardDetails: "會員卡詳情",
HotelGroupMembershipCardInfo: "飯店集團會員卡資訊",
HotelIntroduction: "飯店簡介",
HotelInvoiceExplain: "注：房費發票由飯店開具，請到前臺索取",
HotelLatestRoomPrice: "已為您獲取最新酒店價格",
HotelName: "飯店名稱",
HotelNight: "飯店總間夜",
HotelOtherNeeds: "其他要求",
HotelPayInStore: "到店支付",
HotelPromptIsMorning: "如需今天早晨6點前入住，請選擇今天淩晨入住",
HotelRank: "飯店差標",
HotelRating: "飯店星(鑽)級",
HotelRC: "飯店RC",
HotelReasonCodeDistribution: "飯店ReasonCode分布",
HotelReservationAuthority: "國內飯店差標",
HotelRestaurant: "飯店餐廳",
HotelRoomNumber: "房間數量",
HotelRoomRemarkInputTip: "如果有其他需求請填寫，酒店會盡量為您安排",
Hotels: "的飯店",
HotelSelectChargebackGuest: "選擇取消的入住人",
HotelService: "飯店服務",
HotelServicePreferences: "服務·喜好",
HotelServicePreferences_1: "服務喜好",
HotelSpecialNeeds: "特殊要求",
HotelSpecialNeedsEditHint: "如果有其他要求請填寫，酒店會盡量為您安排。",
HotelSpecialNeedsExplain: "我們會把您的要求轉達給酒店，但不保證酒店一定可以滿足，如有特殊要求，請自行聯繫酒店。",
HotelTotalAmount: "飯店總金額",
HsTravelerExplainContext: "您已知悉您在恒順商旅輸入的常旅客身份資訊，將用於您預訂機票、火車票等所有需要實名制的產品，並在使用時進行驗證，請確保此資訊的真實有效，恒順商旅將透過加密等方式保護此資訊，且僅在有具體交易時授權提供給相關第三方。",
HsTravelerExplainTitle: "閱讀並同意以下內容",
ID: "證件號",
IDCard: "身份證號",
IDCardFormatIsIncorrect: "身份證號碼格式不正確！",
IDNumber: "證件號碼",
IDType: "證件類型",
IfTheApproverIsInconsistent: "審批人不一致請分開下單",
IfTheRemainingSeats: "若剩餘座位無法滿足選座需求，系統將自動為您分配座位",
IfTheRemainingSeatsCannotMeetTheSeatSelectionRequirements: "若剩餘座位無法滿足選座需求，系統將自動為您分配座位。",
IfTheTicketHasBeenIssuedTheLossMustBeBorneByTheOwner: "2.若已出票，退改損失需自行承擔。",
IfYouCheckThisOption: "如果您勾選了此選項，接下來的行程將視為您的私人行程",
IfYouHaveAnyQuestions: "如果您有任何疑問，請致電恒順商旅全國服務熱線：4006-123-123",
IfYouHaveAnyQuestionsPleaseContactYourBusinessConsultant: "如有疑問請聯繫您的業務顧問",
IfYouNeedToNarrowTheScopePleaseEnterMoreConditions: "若需縮小範圍，請輸入更多條件。",
IGotIt: "我知道了",
IHaveReadAndAgree: "已閱讀並同意",
Import: "導入",
Import_1: "導 入",
ImportBusiness: "導入(因公)",
ImportFromFrequentTraveler: "從員工中導入",
In: "住",
IncludingHongKongMacauAndTaiwan: "含港澳台",
Income: "收入",
IncomeExpenditure: "收支明細",
InconsistentPasswordEnteredTwice: "兩次輸入的密碼不一致",
IncorrectFormatOfContactMobileNumber: "聯繫人手機號格式不正確！",
Index_x: "%a$程",
Indexs: "字母索引",
InformationIsIncomplete: "基本資訊不完整",
InformationVerification: "資訊驗證",
Infrastructure: "基礎設施",
InitiativeEnthusiasmEfficientMeticulousSpecialty: "更主動 更熱情 更高效 更細緻 更專業",
InnovativeService: "用心創新服務",
InputInformation: "輸入資訊",
InquiryInformationIsInvalid: "查詢資訊失效",
Install: "立即安裝",
InsufficientRemainingTicketsChooseOtherFlight: "該艙位餘票不足，請選擇其他艙位",
Insurers: "保險",
Integral: "積分",
Integral_1: "商品積分",
IntegralByHomsom: "恆順商旅積分",
IntegralMall: "積分兌換",
Intergral_x: "%a$積分",
IntergralDeliveryNumber_x: "快遞單號：%a$",
IntergralDescription: "積分說明",
IntergralRules: "積分規則",
IntergralTotalColon: "總計：",
InternalProcurement: "內部採購",
International: "海外",
InternationalHotel: "海外飯店",
InternationalHotelOrderConfirmation: "海外飯店訂單確認",
InternationalHotelOrders: "海外飯店訂單",
InternationalRegion: "國際/中國港澳台",
InternationalRegion_1: "國際機票·港澳台",
InternationalTicket: "國際機票",
Internet: "上網",
InTheLocation: "定位中",
InTheTicket: "出票中",
Intl_1: "國際",
IntlDestination: "海外 目的地",
IntlFlightEnquiry: "國際機票需求單",
IntlFlightEnquiryService: "國際機票詢價服務",
IntlFlightEnquiryServiceDesc: "人工詢價，省時更省心",
IntlFlightOrder: "國際機票訂單",
IntlFlightRank: "國際機票差標",
IntlFlights: "國際機票",
IntlFlightsOrderConfirmation: "國際機票訂單確認",
IntlHotel: "海外飯店",
IntlHotelReservationAuthority: "海外飯店差標",
InTravel: "出行中",
Invalid: "已失效",
Inventory_x: "庫存：%a$件",
InvitationOtherType: "其他邀請方式",
InvitationStaff: "邀請員工",
Invitee: "邀請人",
InviteEmployeesToJoin: "通過下列任意方式邀請員工加入團隊，管理員審核人員資訊後，員工即可收到簡訊通知。",
Invoice: "發票",
InvoiceAmount: "開票金額",
InvoiceContent: "發票內容",
InvoiceDescription: "開票說明",
InvoiceDetais: "發票詳情",
InvoiceFilling: "發票填寫",
InvoiceFinished: "已開立發票",
InvoiceHeader: "發票抬頭",
InvoiceHeaderName: "抬頭名稱",
InvoiceHeaderType: "抬頭類型",
InvoiceInformation: "開票資訊",
InvoiceInst: "開票說明",
InvoiceOperating: "開立發票中",
InvoiceParagraph: "發票稅號",
InvoiceRemarks: "發票備註",
InvoiceTitle: "發票抬頭",
InvoiceType: "發票類型",
InvoicingStatus: "開立發票狀態",
InvoluntaryRefund: "非自願退票",
InvoluntaryRefundDesc: "非自願退票(航班取消/延誤30分鐘以上，以航空公司審核為準)",
InvoluntaryRefundExplain: "非自願退票說明",
InvoluntaryReschedule: "非自願改期",
InvoluntaryRescheduleDesc: "非自願改期(航班取消/延誤30分鐘以上，以航空公司審核為準)",
InvoluntaryRescheduleExplain: "非自願改期說明",
IsLoggedIn: "已登入",
IsNotAArrivalCity_x: "%a$不能作為到達城市",
IsNotADepartureCity_x: "%a$不能作為出發城市",
IsSendReceiveEmail: "接單郵件",
IsSendReceiveSms: "接單簡訊",
ItIsNowPastZeroOclock_x: "當前已過0點，如需凌晨%a$點前入住，請選“今天凌晨”入住",
JobDescription: "差標描述",
JPY: "日圓",
JudgeCertificateExpired_x: "%a$當前證件有效期與起飛時間比較小於6個月，有可能會導致無法登機，是否繼續？",
JustLookAtTheHighSpeedTrain: "只看高鐵動車",
JustTime: "剛剛",
Keywords: "關鍵詞",
KeywordSearch: "關鍵字搜尋(酒店名)",
KeywordSearch_1: "位置/品牌/酒店名",
Kilometers: "行駛了%a$公里",
KPIAnalysis: "機票KPI分析",
KRW: "韓幣",
LackOfIntegral: "積分不足",
Language: "多語言",
LanguageBeingSet: "正在設定語言...",
LanguageCH: "中文",
LanguageDefault: "默認語言",
LanguageEN: "英文",
LanguageSetting: "語言設定",
LastNameFormatIsIncorrect: "英文姓格式不正確！",
LatestArrivalDate: "最晚到賬日期",
LateToEarly: "起飛時間 晚-早",
Latitude: "多緯度分析",
LearnAbout: "溫馨提醒",
LearnMore: "了解超標付",
LengthMustNotExceedXDigits_x_x: "%a$長度不得超過%b$位",
LessThan10People: "10人以下",
LetYourFriendsAlsoDownloadHengshunTravelAPP: "讓您的朋友也可以下載恒順商旅APP",
LevelXAapproval_x: "%a$級審批",
Light: "淺色模式",
LimitedToXReservation_x: "僅限%a$預訂",
LinkYourCellphone: "綁定手機",
List: "列表",
Loading: "載入中",
Loading_1: "正在刷新...",
LoadMoreTransit: "載入更多中轉車次資訊",
LocacleHotHotelRecommend: "當地熱門酒店推薦",
LocalTax: "地方稅",
LocalTime: "當地時間",
Location: "當前位置",
Location_1: "定位",
Location_2: "位置距離",
LocationFailed: "獲取當前位置失敗",
LocationNotTurnOn: "定位未啟用",
Login: "登入",
Login_1: "登入",
Login12306: "登入12306",
Login12306AccountEnjoyExtremeReversionMemberPoints: "登入12306帳號，享極致退改、會員積分",
LoginByAccountPassword: "帳號密碼登入",
LoginByVerificationCode: "驗證碼登入",
LoginByVerificationCodeEmail: "郵箱驗證碼登錄",
LoginByVerificationCodeSMS: "簡訊驗證碼登錄",
LoginConfirm: "確認登入",
LoginExpired: "登入已過期，請重新登入！",
LoginName: "登入帳號",
LoginOut: "登出目前帳號",
LoginSuccessful: "登入成功",
LoginTo12306ToImproveTheSuccessRateOfTicketIssuance: "登入12306帳號提高訂票成功率",
LogisticsCompany: "物流公司",
Logout: "登出",
LongPressSaveTheQRcode: "長按圖片保存QR碼",
LookAtThePriceExcludingTax: "看不含稅價",
LookCertificate: "查看證明",
LookChargebackOrder: "查看退單",
LooOriginOrder: "查看原單",
Loss: "損失",
LossAnalysis: "損失分析",
LossPercentage: "損失占比",
LowCarbonHomsomPlantingHomsomForestTogether: "低碳恆順，共植恆順林",
LowestPrice: "價格最低",
LowestPrice_1: "最低價",
LowestPriceDetails: "最低價詳情",
LowestPriceHoursAndTicket_x_x: "前後%a$小時最低價 %b$/張",
LowestPriceTicketBookingRatio: "最低價票預訂比例",
LowestTicketPrice: "機票最低價",
LowtoHigh: "價格 低-高",
LuggageStorage: "行李寄存",
Luxury: "豪華",
LuxuryHotel: "豪華型飯店",
LuxuryHotel_1: "豪華",
Lxd: "旅行豆",
LxdReward: "旅行豆獎勵",
LxdReward_x: "%a$個旅行豆",
LxdRewardCompleteTheOrderToObtain: "完成訂單後可獲得",
LxdRules: "旅行豆規則",
LxdToCompleteTheOrder_x: "完成訂單後可獲得%a$個旅行豆",
MailSent: "郵件已發送",
MainFlight: "主航班",
MainRoomer: "主入住人",
MainRoomerRequired: "主入住人（必選）",
MakeSureToClearCache: "確定清除快取？",
MakeSureToFingerprintLoginClose: "確定關閉指紋登錄？",
MakeSureToLeaveYourCurrentAccount: "確定退出當前帳戶？",
Male: "男",
Manage: "管理",
Manage_x: "%a$管理",
Management: "管理",
Manager: "經辦人",
ManualApproval: "人工審批",
ManuallyImport: "手工導單",
ManuallyPassed: "人工通過",
Map: "地圖",
MapNavigation: "地圖導航",
MapPointSelection: "地圖選點",
MapSelection: "圖選",
MapSurroundings: "地圖/周邊",
MarkAllMessagesAsRead: "將全部消息標記為已讀？",
MatchingTheBestDriverForYou: "正在為你匹配最優司機",
MaximumFaceValue: "最高票面價",
MayBeAt: "可能在",
Meals: "有餐食",
MealsGoods: "用餐",
MealsOrder: "用餐訂單",
MealsOrderConfirm: "用餐訂單確認",
MealsRank: "用餐差標",
MealsRemarks: "用餐備註",
MeetingRoom: "會議室",
MembershipNumber: "會員卡號：",
Message: "消息",
MessageCenter: "訊息中心",
MessageFailedToSend: "訊息傳送失敗，請重試",
MessageSendSuccessfully: "訊息傳送成功",
MicrophoneIsRequiredForCurrentService: "目前服務需要使用麥克風，請前往設定開啟",
Midscale: "舒適",
Mileage: "里程數",
Minute: "分鐘",
Minutes_x: "共花費%a$分鐘",
MissedSavingAnalysis: "未預訂最低價損失",
MixedPayment: "超標付",
MixedPaymentContent_x_x_x: "您預訂的訂單總金額%a$，差旅標準最高%b$，已超出%c$，您可以選擇超標部分自行支付，或者選擇全部公司支付。",
MixedPaymentDesc: "超差標部分自付，優質酒店超標付",
MixedPaymentDetails_x_x: "公司將支付%a$，需個人支付超出差旅標準金額%b$",
MixedPaymentOpen: "貴公司已開通超標付",
MixedPaymentOpenDesc: "自行支付超標部分即可享受更好的房間",
MixedPaymentSelfPayment: "超標部分自付",
MnlyOneMembershipCardCanBeAddedToTheSameHotel: "非常抱歉，同一家飯店只能添加一張會員卡。",
MobileCode: "手機區號",
MobileInvitationCode: "手機邀請碼",
MobileInvitationCodeShare: "您將含有二維碼的圖片分享到公司社群，您的同事可以直接掃碼申請",
MobileLinked: "手機號碼已綁定",
MobileUnlinked: "手機號碼未綁定",
ModifiedSuccess: "修改成功",
ModifiedSuccessfully: "修改成功！新的手機號碼為",
Modify: "修改",
ModifyTheBoundPhone: "修改綁定手機",
Moments: "朋友圈",
Month: "月",
MonthApr: "四月",
MonthAug: "八月",
MonthDeb: "二月",
MonthDec: "十二月",
MonthDetails: "本月明細",
MonthJan: "一月",
MonthJul: "七月",
MonthJun: "六月",
Monthly: "月度",
MonthlyExpense: "逐月消費",
MonthlyKPI: "逐月KPI",
MonthlySummary: "月度匯總",
MonthMar: "三月",
MonthMay: "五月",
MonthNov: "十一月",
MonthOct: "十月",
MonthOnMonth: "環比",
MonthSep: "九月",
More_1: "更多",
More_2: "查看更多",
More_3: "查看<br/>更多",
MoreAvailable_x: "滿%a$元可用",
MoreCabinsNoResult: "沒有更多艙位了！",
MoreConsultation: "更多諮詢",
MoreDate: "更多日期",
MoreDateB: "更多\n日期",
MoreDiner: "更多用餐人",
MoreFilterCriteria: "更多篩選條件",
MorePassengerFlight: "更多乘機人",
MorePassengerRoomer: "更多入住人",
MorePassengerVehicle: "更多乘車人",
MorePrice: "更多價格",
MoreSorting: "更多排序",
MoreThan100People: "100人以上",
MoreThanThePurchaseLimitCanNotBuy: "超過限購數量，無法購買",
MoreTraveler: "更多出行人",
Morning: "凌晨",
MorningCall: "叫醒服務",
MultipleChoice: "多選",
MultipleChoiceSuccessRate: "多選提高成功率",
MultiTierReschedulingNotSupported: "不支援多個行程改期",
MustBeAccompaniedByAnAdultOnBoard: "兒童/嬰兒需由成人陪同乘機",
MyAdvancePayment: "我的預付款",
MyApplyTravel: "我的出差申請",
MyApprovals: "待我審批",
MyBalance: "我的餘額",
MyCollection: "我的收藏",
MyCustomerService: "我的客服",
MyInitiations: "我發起的",
MyIntergral: "我的積分",
MyLxd: "我的旅行豆",
MyTrip: "我的行程",
Name: "姓名",
Name_x: "%a$名稱",
NameFillingInstructions: "姓名填寫說明",
Nation: "國家/地區",
NationalityOfThePerson: "入住人國家/地區",
NationalServicePhone: "全國服務熱線",
Near: "鄰近",
NearExpiration: "臨近過期",
NearlyOneYear: "近1年",
NetList: "網單",
NetTransactionPrice: "成交淨價",
Network: "網路",
NetworkRequestConnectionFailed: "網路連線失敗",
NetworkRequestFailed: "網路請求失敗",
New: "新增",
New_1: "新 增",
New_x: "新增%a$",
NewCertificate: "新增證件",
NewContactSuccessFailed: "新增聯絡人失敗",
NewContactSuccessFul: "新增聯絡人成功",
NewDepartment: "新增部門",
NewDiner: "新增用餐人",
NewFlightDate: "新班機日期",
NewFrequentTravelCard: "新增常旅卡",
NewHotelGroupMembershipCard: "添加飯店集團會員卡",
NewMobile: "新的手機號碼",
NewPassenger: "新增乘客",
NewPassengerFlight: "新增乘機人",
NewPassengerVehicle: "新增乘車人",
NewRC: "新增RC",
NewRoomer: "新增入住人",
NewTraveler: "新增出行人",
NewVersion: "使用新版",
NewVersionFound: "發現新版本",
NextStep: "下一步",
NextStep_1: "下 一 步",
NickName: "昵稱",
NightsTotalPrice_x: "%a$晚總價",
NoApprovalRequired: "無需審批",
NoAvailableCabin: "暫無可預訂艙位",
NoAvailableFlight: "暫無可預訂班機",
NoAvailableFlightDesc: "沒有找到符合條件的班機，您可以更改篩選項試試~",
NoAvailableVouchersForUse: "暫無可用抵用券",
NoBreakfast: "無早",
NoChangeablePassengers: "無可改簽的乘客",
NoChildUnderDepartmentsAtPresent: "當前部門下暫無子部門",
NoCollection: "暫無收藏",
NoData: "暫無結果",
NoData_1: "暫無資料",
NoIncludedChangeFeeTag: "(未包含改期費)",
NoMeal: "無餐食",
NoMore: "沒有更多了",
NoMoreCredential: "暫時沒有更多證件了",
NonBookable: "不可訂",
None: "無",
NoNewNotice: "沒有新通知",
NoNews: "暫無消息",
NonStaff: "外部人員",
NonStaffDetail: "外部人員詳情",
NonStaffManagement: "外部人員管理",
Noon: "中午",
NoOrder: "暫無訂單",
NoPermissions: "沒有權限",
NoPlatformAvailablePleaseContactYourTravelConsultant: "暫無可用平台，請聯繫您的差旅顧問",
NoRemainingTickets: "暫無餘票，不可預訂",
NoReschedulingReeIsIncluded: "未包含改期費",
NoResult: "沒有結果",
NormalSeat: "商務座",
NoStay: "未入住",
NotBook: "不可預訂",
NotBooking_1: "不 可 預 訂",
NotCertificateInfo: "暫無證件資訊",
NotesOnRefundAndChangeOfVisa: "退改簽注意事項",
Nothing: "暫無",
Notice: "通知",
NoticeCanceled: "通知取消",
NoTicket: "無票",
NotInitiated: "未發起",
NotNecessaryChoose: "非必選",
NotNecessaryChoosePleaseChoose: "請選擇",
NotNecessaryChooseUneditable: "不可編輯",
NotNecessaryFill: "非必填",
NotNecessaryFillPleaseFill: "請填寫",
NotNecessaryFillPleaseFillOrChoose: "請填寫或選擇",
NotNecessaryFillUneditable: "不可編輯",
NotNeedDepartPassenger: "沒有需要出發的乘客",
NoTravelCard: "暫無常旅卡資訊",
NotRefundable: "不可退票",
NoTrip: "當前您還沒有任何行程資訊",
NotSelectable: "不可選擇",
NotSendEmptyInfo: "不可傳送空白訊息",
NotUsed: "不使用",
Now: "現在",
NoXSegment_1_x: "選第%a$程",
NoXSegment_2_x: "選為第%a$程",
NoXSegment_x: "第%a$程",
Number: "人數",
Number_1: "數量",
NumberOfAdult: "總成人數",
NumberOfChild: "總兒童數",
NumberOfCustomItems: "自訂項目數",
NumberOfFullPriceTickets: "全價票張數",
NumberOfGuestsPerRoom: "每間房入住成人數",
NumberOfRoom: "間數",
NumberOfRooms: "房間數",
NumberOfRoomsAndOccupancy: "房間數量及入住人數",
NumberOfRoomsBooked: "預訂房間數",
NumberOfSheets: "張數",
NumberOfTrainTickets: "火車票張數",
NumberOfWorkers: "員工數量",
NZD: "新西蘭元",
OffAndBelow: "折及以下",
OK: "知道了",
OldAndNewPasswordsMustNotBeTheSame: "新舊密碼不得相同",
OldVersion: "使用舊版",
OneAirlineCanOnlyAddOneFrequentTravelCard: "非常抱歉，同一家航空公司只能添加一張常旅卡。",
OneWay: "單程",
OnlineChangeNotSupported: "暫不支援線上改簽",
OnlineCheckIn: "線上值機",
OnlineFlight: "值機",
OnlineFlight_1: "去值機",
OnlineFlightWarn: "溫馨提示：網上值機服務由航空公司提供，您辦理值機產生的任何問題請直接與航空公司聯系。",
OnlinePayment: "線上支付",
OnlinePayment_1: "線上付",
OnlineReport: "線上管理報告",
OnlineService: "線上客服",
OnlyAdultTicketBookingsAreSupported: "僅支援成人票預訂",
OnlyAlternativeTrainsCanBeSelectedAtMost_x: "最多只能選擇%a$個備選車次",
OnlyChineseName_x: "%a$只能使用中文名",
OnlyDirectFly: "僅看直飛",
OnlyEnglishName_x: "%a$只能使用英文名",
OnlySeeSelected: "僅看已選",
Open: "開通",
Open_1: "去開啟",
Open_2: "打開",
OpenHengshunTraveMobilelEditionForBetterExperience: "打開恆順商旅行動版，使用體驗更佳",
Opening: "開通",
OpenSafari: "Safari打開",
OpenSuccess: "開通成功",
OpenYears: "開業時間",
OperatedBy: "實際承運",
Operating: "處理中",
Operation: "操作",
Operator: "處理人",
Optional: "可選",
OptionalCities: "可選城市",
Order: "訂單",
Order_1: "預約",
Order_2: "訂單問題",
Order_3: "兌換記錄",
OrderCancelFailed: "訂單取消失敗",
OrderCancelled: "訂單已取消",
OrderCompleted: "訂單已完成",
OrderDate: "下單日期",
OrderDateBookTime: "下單時間",
OrderDetails: "訂單詳情",
OrderDetailsBusiness: "訂單詳情(因公)",
OrderDetailsPersonal: "訂單詳情(因私)",
OrderFilling: "訂單填寫",
OrderInformation: "訂單資訊",
OrderInformationForChange: "改期訂單資訊",
OrderInformationForRefund: "退票訂單資訊",
OrderIntergral: "訂單積分",
OrderNo: "訂單編號",
OrderNo_1: "兌換編號",
OrderNoCannotBeEmpty: "訂單號不能為空",
OrderNotice: "訂單通知",
OrderNoticeDesc: "出票狀態、預訂狀態提醒",
OrderNumber: "訂單號",
OrderNumber_1: "編號",
OrderNumber_x: "訂單號%a$",
OrderPaid: "訂單已支付",
OrderStatus: "訂單狀態",
OrderStatusHasChangedPleaseReOperate: "訂單狀態已更改，請重新操作",
OrderSubmissionSuccessFailed: "訂單提交失敗",
OrderSubmissionSuccessful: "訂單提交成功！",
OrderSynTime: "同步時間",
OrderTime: "訂單時間",
OrderType: "訂單類型",
OrganizationalStructure: "組織架構",
Original: "原",
OriginalPrice: "原價",
OriginalSchedule: "原訂行程",
OriginFlightOrderNo: "原班機訂單號",
OriginFlights: "原航班",
OriginOrderNumber: "原訂單",
Other: "其他",
Other_x: "其他: %a$",
OtherInfo: "其他資訊",
OtherInformation: "其他資訊",
OtherLoginMethods: "其他登錄方式",
OtherPolicy: "其他規定",
OtherReasons: "其他原因",
OurAgreedAveragePrice: "我司協議均價",
OurCompany: "我司",
Out: "離",
OverseasMultiCity: "多程",
OverseasServicePhone: "境外服務熱線",
Overview: "總概",
OverviewOfTickets: "機票總概",
P: "私",
PackUp: "收起",
Page: "頁",
PaidBoardband: "收費寬帶",
PaidParking: "收費停車場",
PaidPickUpService: "收費接機服務",
PaidWiFi: "收費WiFi",
PartialChange: "部分改簽",
PartialRefund: "部分退票",
Pass: "通過",
Passenger: "乘客",
PassengerDetails: "乘客詳情",
PassengerFlight: "乘機人",
PassengerFlightDetails: "乘機人詳情",
PassengerFlightName: "乘機人姓名",
PassengerInformation: "乘客資訊",
PassengerName: "乘客姓名",
PassengersWhoNeedToRefund: "需要退票的乘機人",
PassengersWhoNeedToReschedule: "需要改期的乘機人",
PassengersWithoutRefundableTickets: "無可退票的乘客",
PassengerVehicle: "乘車人",
PassengerVehicleDetails: "乘車人詳情",
PassengerVehicleName: "乘車人姓名",
Password: "密碼",
Password12306: "12306密碼",
PasswordCanBeResetViaEmail: "可通過已綁定的郵箱地址重設密碼",
PasswordCanBeResetViaPhone: "可通過已綁定的手機號+短訊驗證碼重設密碼",
PasswordMustBeLettersAndNumbers: "必須為8-20位字母和數字",
PasswordMustBeNoLessThanEightDigitsInLength: "密碼長度不得小於8位",
PasswordMustBeNoLessThanSixDigitsInLength: "密碼長度不得小於6位",
PasswordRuleDescription: "建議密碼由大小寫字母和數字組成，且長度大於8位",
PasswordsMustContainLettersAndNumbers: "密碼必須包含字母和數字",
PasswordUpdateSuccessful: "密碼修改成功",
Pay: "支付",
PayAgain: "重新支付",
PayAtTheStore: "到店付",
PayAtTheStore_x: "到店付%a$",
PayAtTheStoreAbout: "到店支付 约",
PayAtTheStoreAbout_x: "到店支付 约 %a$",
PayAtTheStoreTaxesFees_x: "到店另付稅/費 約%a$",
PayBackTips: "訂單尚未支付，返回將放棄支付，您可以在訂單中查看並繼續支付!",
PayInformation: "支付資訊",
PayInformationDetails: "支付詳情",
PaymentFailed: "支付失敗",
PaymentOnBehalfShareDesc: "發給他人幫你付款吧~",
PaymentPermission: "支付權限",
PaymentSerialNumber: "支付流水號",
PaymentSuccessful: "支付成功",
PaymentSuccessfulContinueToPayTheNextOrder: "支付成功，繼續支付下一個訂單",
PaymentTime: "支付時間",
PaymentWay: "支付方式",
PayNow: "立即支付",
PayOrder: "支付訂單",
PayTypeCompanyDesc: "由公司帳戶支付費用",
PayTypeMixedDesc: "超出差旅標準的費用需個人支付",
PayTypePersonalDesc: "需個人支付費用",
PdfDownload: "PDF下載",
PendForOperating: "待處理",
Pending: "待審批",
Pending_1: "申請已提交(待審批)",
Pending_2: "預訂成功(待審批)",
Pending_3: "申請已提交",
Pending_4: "待審核",
Pending_x: "待審核(%a$)",
People_x: "%a$人",
People10: "10-20人",
People20: "20-40人",
People40: "40-100人",
PeoplePerRoom: "每間入住",
PeopleStaying_x: "%a$人入住",
PercentageOfPotentialSavings: "潛在節省占比",
PercentageOfRoomNights: "間夜占比",
PercentageOfSavings: "節省占比",
PercentageOfSheets: "張數占比",
PermissionsRange: "權限範圍",
Person: "個人",
Personal: "因私",
Personal_x: "%a$(因私)",
PersonalInformation: "個人資訊",
PersonalPayment: "個人支付",
PersonalPermissions: "個人權限",
PersonalTools: "個人工具",
PersonalTravelInformation: "個人差旅信息",
PersonalTrip: "因私出行",
PersonalTrip_1: "私人出行",
PersonalWallet: "個人錢包",
PersonalWorkbench: "個人工作台",
PhoneNumberFormatIsIncorrect: "號碼格式不正確！",
PhoneNumberFormatIsIncorrect_1: "手機號碼格式不正確！",
PhoneNumberFormatIsIncorrect_x: "%a$的手機號碼格式不正確",
PhoneNumberOrEmailFormatIsIncorrect: "手機號/郵箱的格式不正確",
Photo: "照片",
PhotoOrFile: "照片/文件",
PickUpUseCarTimeTenMinute: "落地後10分鐘",
PickUpUserCarTimeFiftyMinute: "落地後50分鐘",
PickUpUserCarTimeFourtyMinute: "落地後40分鐘(有行李托運推薦)",
PickUpUserCarTimeSixtyMinute: "落地後60分鐘",
PickUpUserCarTimeThirtyMinute: "落地後30分鐘(無行李托運推薦)",
PickUpUserCarTimeTwentyMinute: "落地後20分鐘",
Picture: "圖片",
PlaceOfDeparture: "出發地點",
PlaceOfService: "送達地點",
Plan: "原計劃",
Plan_x: "原計劃%a$",
Plane: "機型",
PlaneBookNoticeCopyTips: "已複製連結到剪切板，小程序暫不支持預覽，請在瀏覽器打開",
PlaneConstruction: "機建",
PlaneConstructionFee: "機建費",
PlaneConstructionFuel: "機建 / 燃油",
PleaseAddARoomer: "請添加入住人",
PleaseAddContact: "請添加聯繫人",
PleaseCheckInAccordingToTheActualFlight: "請按實際搭乘航班辦理值機。",
PleaseCheckTheBoxToAgreeAfterReadingTheRelevantTermsAndConditions: "請閱讀相關條款後勾選同意",
PleaseChoose: "請選擇",
PleaseChoose_1: "請選擇",
PleaseChooseAPlaceOfArrival: "請選擇送達地點",
PleaseChooseAPlaceOfDeparture: "請選擇出發地點",
PleaseChooseApprover: "請選擇審批人",
PleaseChooseASeat: "請選座",
PleaseChooseCarType: "請選擇車型",
PleaseChooseContacts: "請選擇聯繫人",
PleaseChooseIDType: "請選擇證件類型",
PleaseChooseOrInput_x: "請選擇/填寫%a$",
PleaseChoosePayWay: "請選擇支付方式",
PleaseChooseRouteArrivalCity_x: "請選擇第%a$程到達城市",
PleaseChooseRouteDepartCity_x: "請選擇第%a$程出發城市",
PleaseChooseRouteGoDate_x: "請選擇第%a$程出發時間",
PleaseChoosesCabinLevel: "請選擇艙位等級",
PleaseChooseTheAirClub: "請選擇航司俱樂部",
PleaseChooseTheDocumentSignatory: "請選擇證件簽署國",
PleaseChooseTheHotelGroupMembership: "請選擇飯店集團",
PleaseChooseTheReason: "請您選擇原因",
PleaseChooseTheReason_1: "請您選擇原因:",
PleaseChooseTheValidityPeriodOfTheCertificate: "請選擇證件有效期",
PleaseChooseWhenToUseTheCar: "請選擇用車時間",
PleaseChooseYourNationality: "請選擇國家/地區",
PleaseCompleteTheInformation_x: "請补全%a$的資訊",
PleaseCompleteTheReceiptInformation: "請完善收貨資訊",
PleaseCompleteTheVerificationAsSoonAsPossible: "請您儘快完成核驗，以免影響出票",
PleaseCompleteYourGivenNames: "請完善您的英文名",
PleaseCompleteYourNameInformation: "請完善您的姓名資訊",
PleaseCompleteYourSegments: "請完善您的行程",
PleaseCompleteYourSurname: "請完善您的英文姓",
PleaseConfigureTheExaminerInTheBackground: "請在後台配置審批人",
PleaseConfirmIfWeChatPaymentHasBeenCompleted: "請確認微信支付是否已完成",
PleaseConfirmTheNewPassword: "請確認新密碼",
PleaseContactTheAccountNumber12306: "請關聯12306帳號",
PleaseDoNotRefreshThisPagePromptOperation: "請勿重新整理本頁面，按照手機提示操作",
PleaseEnter: "請輸入",
PleaseEnter_1: "請輸入要查找的",
PleaseEnter_1_x: "請輸入要查找的%a$",
PleaseEnter_x: "請輸入%a$",
PleaseEnterAComment: "請輸入備註資訊",
PleaseEnterACostCenterName: "請填寫成本中心",
PleaseEnterAddress: "請輸入詳細地址",
PleaseEnterANewPassword: "請輸入新密碼",
PleaseEnterASuggestionOfNoLessThanEightWords: "請輸入不少於8個字的建議",
PleaseEnterAUsername: "請輸入登入帳號",
PleaseEnterAuthorizationCode: "請輸入授權碼",
PleaseEnterBindingCredentialName: "請輸入要綁定的證件號",
PleaseEnterBindingCredentialType: "請選擇要綁定的證件類型",
PleaseEnterBindingMobile: "請輸入要綁定的手機號",
PleaseEnterBindingUserName: "請輸入要綁定的名字",
PleaseEnterCompnayInfo: "請輸入企業名稱",
PleaseEnterContactName: "請輸入聯繫人",
PleaseEnterCorrectEmail: "請輸入正確的郵箱",
PleaseEnterCorrectMobile: "請輸入正確的手機號",
PleaseEnterEheCorrectFormatOfValidityPeriod: "請輸入正確的信用卡有效期格式",
PleaseEnterIDNumber_x: "請輸入%a$證件號碼",
PleaseEnterInformation: "請輸入資訊",
PleaseEnterKey: "請輸入要查找的關鍵字",
PleaseEnterMobileVerification: "請輸入手機驗證碼",
PleaseEnterPhone: "請輸入收貨人手機號",
PleaseEnterPhoneNumber_x: "請輸入%a$手機號",
PleaseEnterPhoneNumberOrEmail: "請輸入手機號/郵箱",
PleaseEnterRecipient: "請輸入收貨人姓名",
PleaseEnterRegisterInfo: "請輸入註冊資訊",
PleaseEnterSMSCode: "請輸入簡訊驗證碼",
PleaseEnterThe_x: "請輸入要查找的%a$",
PleaseEnterTheAccountForRetrievePassword: "請先輸入要找回密碼的帳號",
PleaseEnterTheAccountInformationYouNeedToRetrieveYourLoginPassword: "請輸入需要找回登入密碼的帳戶資訊",
PleaseEnterTheAirport: "請輸入要查找的機場",
PleaseEnterTheArriveAddress: "請輸入下車地點",
PleaseEnterTheBindingEmail: "綁定的電子郵件",
PleaseEnterTheBindingPhone: "綁定的手機號",
PleaseEnterTheBindingPhoneNumberEmail: "請輸入綁定的手機號碼/電子郵件",
PleaseEnterTheBindingPhoneNumberEmail_1: "綁定的手機號碼/電子郵件",
PleaseEnterTheCardNumber: "請輸入卡號",
PleaseEnterTheCardNumberOrUsername: "請輸入卡號或使用者名稱",
PleaseEnterTheCarRank: "請輸入要查找的用車差標",
PleaseEnterTheCity: "請輸入要查找的城市",
PleaseEnterTheCityNameToSearch: "請輸入城市名進行搜索",
PleaseEnterTheCompanyAddress: "請輸入公司地址",
PleaseEnterTheCorrectAccountPassword: "請輸入正確的帳號密碼",
PleaseEnterTheCorrectCreditCardNumber: "請輸入正確的信用卡號",
PleaseEnterTheCorrectMobilePhoneNumberEmail: "請輸入正確的手機號碼/電子郵件",
PleaseEnterTheCorrectVerificationCode: "請輸入正確的驗證碼",
PleaseEnterTheCostCenter: "請輸入要查找的成本中心",
PleaseEnterTheCountry: "請輸入要查找的國家/地區",
PleaseEnterTheCurrentPassword: "請輸入目前的密碼",
PleaseEnterTheCustomItems: "請輸入要查找的自訂項目",
PleaseEnterTheDepartAddress: "請輸入上車地點",
PleaseEnterTheDepartment: "請輸入要查找的部門",
PleaseEnterTheFlightBookPermission: "請輸入要查找的機票預訂權限",
PleaseEnterTheHomeAddress: "請輸入家庭地址",
PleaseEnterTheHotelBookPermission: "請輸入要查找的飯店預訂權限",
PleaseEnterTheHotelCity: "城市/地標/飯店名",
PleaseEnterTheIntlHotelCity: "都市/飯店名",
PleaseEnterTheLastThreeDigitsOfTheCVVSecurityCode: "請輸入CVV安全碼後三比特",
PleaseEnterTheName: "請輸入要查找的姓名",
PleaseEnterTheNameOfThePerson: "請輸入入住人姓名或訂單號",
PleaseEnterTheNameOfThePerson_1: "入住人姓名或訂單號或飯店名稱",
PleaseEnterTheNameOfTheRank: "請輸入要查找的差標名稱",
PleaseEnterTheNameOfTheRole: "請輸入要查找的角色名稱",
PleaseEnterTheNewPassword: "必須為8-20位字母和數字",
PleaseEnterTheOldPassword: "請輸入原密碼",
PleaseEnterThePassengerName: "請輸入乘客姓名或訂單號",
PleaseEnterThePassword: "請輸入登入密碼",
PleaseEnterTheSystemRole: "請輸入要查找的系統角色",
PleaseEnterTheTemplate: "請輸入要查找的審批模板名稱",
PleaseEnterTheTrainAccountNewPassword: "6-20位字母和數字",
PleaseEnterTheTrainBookPermission: "請輸入要查找的火車票預訂權限",
PleaseEnterTheTrainStation: "請輸入要查找的火車站",
PleaseEnterTheTraveler: "請輸入要查找的姓名/郵箱/手機號/員工編號",
PleaseEnterTheTraveler_1: "蒐索姓名/郵箱/手機號/員工編號/昵稱",
PleaseEnterTheUnitCodeOrUnitShortName: "請輸入單位編號/簡稱/全稱",
PleaseEnterTheValidityPeriodOfTheCreditCard: "請輸入信用卡有效期",
PleaseEnterYourAccountInformation: "請輸入帳號資訊",
PleaseEnterYourExceedReason: "請填寫超標原因",
PleaseEnterYourNewPasswordAgain: "請再次輸入新密碼",
PleaseFill: "請填寫",
PleaseFill_x: "請填寫%a$",
PleaseFill_x_x: "請填寫%a$的%b$",
PleaseFillBudget: "請填寫預算",
PleaseFillBudget_x: "請填寫%a$的預算",
PleaseFillIn: "請填寫新的手機號碼。",
PleaseFillIn_x: "請輸入%a$名稱",
PleaseFillInCostCenterName: "請輸入成本中心",
PleaseFillInDepartmentName: "請輸入部門名稱",
PleaseFillInDiner: "請填寫用餐人姓名",
PleaseFillInEmail_x: "請填寫%a$的電子郵件",
PleaseFillInFirstName: "請填寫英文名",
PleaseFillInGuestName: "請填寫入住人姓名",
PleaseFillInIDNumber: "請填寫證件號",
PleaseFillInLastName: "請填寫英文姓",
PleaseFillInMobile_x: "請填寫%a$的手機號碼",
PleaseFillInName: "請填寫姓名",
PleaseFillInNewPhoneCurrentPhone: "請填寫新的手機號碼。目前手機號碼：",
PleaseFillInOrderNumber: "請填寫訂單號碼",
PleaseFillInPassengerName: "請填寫乘客姓名",
PleaseFillInPhoneNumber: "請填寫手機號碼",
PleaseFillInRC: "請輸入RC",
PleaseFillInTheContactMobilePhoneNumber: "請填寫聯絡人手機號碼",
PleaseFillInTheContactMobilePhoneNumber_x: "請填寫聯絡人%a$的手機號碼",
PleaseFillInTheReasonForRefund: "請填寫退票原因",
PleaseFillInTheReasonForRescheduling: "請填寫改期原因",
PleaseFillInTheShippingAddress: "請填寫送貨地址",
PleaseFillInVerificationCode: "請填寫驗證碼",
PleaseFillOrChoose_x: "請填寫或選擇%a$",
PleaseFillTotalBudget: "請填寫總預算",
PleaseGetTheVerificationCode: "請先取得驗證碼",
PleaseHoldDownTheSliderAndDrag: "請按住滑塊，拖動到最右",
PleaseHoldThePhoneVertically: "請垂直緊握手機",
PleaseInputCreditCardNumber: "請輸入信用卡號碼",
PleaseInputYourDestination: "請輸入您的目的地",
PleaseMakeSureYouHaveEnoughTimeToCheckIn: "1.購票前請先到值機櫃台確認出票後仍有時間值機。",
pleaseOpenScanOnAlipayScanQRCodeToPay: "請打開手機支付寶的掃一掃，掃描二維碼支付",
pleaseOpenScanOnWeChatScanQRCodeToPay: "請打開手機微信的掃一掃，掃描二維碼支付",
PleasePressBackOnceToExit: "請按一次返回退出",
PleaseReadAndAgreeTo: "請先閱讀並同意《賬戶授權協議》",
PleaseReadAndAgreeTo_x: "請先閱讀並同意%a$",
PleaseReadAndTickTheAgreementFirst: "請先閱讀並勾選協議",
PleaseReselectYourCheckInDate: "請重新選擇入住日期",
PleaseSelect_x: "請選擇%a$",
PleaseSelect_x_x: "請選擇%a$的%b$",
PleaseSelectADepartureDate: "請選擇出發日期",
PleaseSelectADestination: "請選擇目的地",
PleaseSelectAirport: "請選擇機場",
PleaseSelectApprover: "請選擇審批人",
PleaseSelectArea: "請選擇所在地區",
PleaseSelectArrival: "請選擇到達地址",
PleaseSelectArrivalAirport: "請選擇到達機場",
PleaseSelectArrivalCity: "請選擇到達城市",
PleaseSelectArrivalTrainStation: "請選擇到達火車站",
PleaseSelectAtLeastOneCityOverseas: "至少選擇一個國際城市/港澳台",
PleaseSelectAtLeastOneOccupant: "請至少選擇1位入住人",
PleaseSelectBackDate: "請選擇返程日期",
PleaseSelectBusinessTravelScale: "請選擇企業差旅規模(月度)",
PleaseSelectCertificate: "請選擇證明",
PleaseSelectCertificateNo: "請輸入證件號",
PleaseSelectCostCenter: "請選擇成本中心！",
PleaseSelectDepartment: "請選擇部門！",
PleaseSelectDeparture: "請選擇出發地址",
PleaseSelectDepartureAirport: "請選擇出發機場",
PleaseSelectDepartureTrainStation: "請選擇出發火車站",
PleaseSelectDestination: "請選擇城市",
PleaseSelectDiner: "請選擇用餐人",
PleaseSelectedTheCheckInDate: "請選擇入住日期",
PleaseSelectFlightChangeDate: "請選擇班機改期日期",
PleaseSelectFlightChangeDate_x: "請選擇%a$班機改期日期",
PleaseSelectFlightDate: "請選擇航班日期",
PleaseSelectFlightNumber: "請填寫航班號",
PleaseSelectGuest: "請選擇入住人",
PleaseSelectGuestLeastOne: "每間房間必須填寫至少一位入住人",
PleaseSelectHotelArrivalTime: "請選擇到店時間",
PleaseSelectOrFillInMealsRemarks: "請選擇/填寫用餐備註",
PleaseSelectOrFillInPurpose: "請選擇/填寫差旅目的",
PleaseSelectOrFillInTheReasonForCancelReason: "請選擇/填寫取消原因",
PleaseSelectOrFillInTheReasonForExceedingTheStandard: "請選擇/填寫超標原因",
PleaseSelectOrFillInTheReasonForRefundReason: "請選擇/填寫退票原因",
PleaseSelectOrFillInTheReasonForReschedulingReason: "請選擇/填寫改期原因",
PleaseSelectOrFillInTheReasonForViolatingTheTravelRank: "請選擇/填寫違反差旅標準原因",
PleaseSelectOrFillInTheReasonOrPleaseSelectTheMixedPayment: "請選擇/填寫超標原因或選擇超標付",
PleaseSelectPassenger: "請選擇乘客",
PleaseSelectPassengersFirst: "請先選擇乘客",
PleaseSelectPaymentWay: "選擇支付方式",
PleaseSelectPurpose: "請選擇差旅目的",
PleaseSelectSeats: "請選擇座位",
PleaseSelectTheDateOfBirth: "請選擇出生日期",
PleaseSelectTheDepartureCity: "請選擇出發城市",
PleaseSelectTheFavoriteToDeleteFirst: "請先選擇需要刪除的收藏",
PleaseSelectTheFirstSeat: "請選擇第一程座位",
PleaseSelectTheFollowingMethodsForVerification: "請選擇以下方式進行核驗",
PleaseSelectTheItineraryToBeRescheduled: "請選擇要重新安排的行程",
PleaseSelectTheMixedPayment: "請選擇超標付繼續預訂",
PleaseSelectThePassengersToBeChanged: "請選擇需改簽的乘客",
PleaseSelectThePassengerWhoNeedsToChange: "請選擇需要改簽的乘客",
PleaseSelectThePassengerWhoNeedsToRefund: "請選擇需要退票的乘客",
PleaseSelectThePassengerWhoNeedsToRefund_x: "請選擇需要退票的%a$",
PleaseSelectThePassengerWhoNeedsToReschedule: "請選擇需要改期的行程",
PleaseSelectTheRoomAndRoomer: "選擇客房和入住人",
PleaseSelectTheSecondSeat: "請選擇第二程座位",
PleaseSelectTheShippingAddress: "請選擇收貨地址",
PleaseSelectTheTravelApplication: "請選擇出差申請單",
PleaseSelectTheTypeOfQuestion: "請選擇問題類型",
PleaseSelectTrainStation: "請選擇火車站",
PleaseSelectTraveler: "請選擇出行人",
PleaseSendTheAbovePaymentTo: "煩請將以上款項於",
PleaseSure: "請務必確認",
PleaseUploadAttachFile_x: "請上傳%a$",
PleaseVerifyThatYouHaveAFaceIDForLogin: "請驗證已有面容ID，用於登錄",
PleaseVerifyThatYouHaveATouchIDForLogin: "請驗證已有指紋ID，用於登錄",
PointYourFaceAtTheFrontCameraOfYourPhone: "請將臉部對準手機前置攝像頭",
PoliciesServices: "政策與服務",
Policy_1: "政策",
PolicyAddress: "保單地址",
PolicyNoColon: "保單號：",
PopularDestinations: "熱門目的地",
PopularFilters: "熱門篩選",
PopularRoutes: "熱門路線",
Pptimization: "最優方案",
Precautions: "注意事項",
Predict: "預計",
Preferential: "優惠",
Preferential_1: "本單可享",
PremiumHotel: "高檔型飯店",
PremiumHotel_1: "高檔",
Prepay: "預付",
Prepayments: "預付款",
PreQuantifier: "/人",
PressVoice: "按住錄音",
PreviewNotSupportedAndIsCopy: "暫不支持預覽，已複製到剪切板，請在瀏覽器中打開",
Price: "價格",
Price_1: "參考票價",
Price_2: "星級價格",
Price150: "¥150以下",
Price150_300: "¥150-300",
Price300_450: "¥300-450",
Price400: "¥400以下",
Price400_700: "¥400-700",
Price450_600: "¥450-600",
Price600_1000: "¥600-1000",
Price700_1000: "¥700-1000",
Price1000_1300: "¥1000-1300",
Price1300_1800: "¥1300-1800",
PriceCeiling: "價格上限",
PriceDetails: "價格明細",
PriceHighest: "從高到低",
PriceHighToLow: "價格高-低",
PriceHotel: "房價",
PriceLowest: "從低到高",
PriceLowToHigh: "價格低-高",
PriceOfPerson_x_x: "%a$ X %b$人",
PriceOver1000: "¥1000以上",
PriceOver1800: "¥1800以上",
PricePerPerson_x: "%a$/人",
PriceRating: "價格/星級",
PriceRating_1: "價格/鑽級價",
PriceSingleChoice: "價格(單選)",
Print: "列印",
PriorityHighPrice: "高價優先",
PriorityHighRating: "高星優先",
PriorityLowPrice: "低價優先",
PrivacyPolicy: "隱私政策",
Processing: "未處理",
ProcessingCompleteTime: "處理完成時間",
ProcessingTime: "處理時間",
ProduceDesc: "產品描述",
ProduceName: "產品名稱",
Product: "產品",
ProductDesc: "產品說明",
ProductExperience: "產品體驗",
ProductIntroduction: "商品介紹",
ProductOverview: "商品概述",
ProductUseRuleDes: "使用規則",
Progress: "進行中",
PromptDateHotel: "您選擇的是飯店當地日期",
PromptDatePrice: "所選日期為出發地日期，價格變動頻繁，以實際為準",
ProportionOfContractedHotelStarLevelTransactions: "協議飯店星級交易額占比",
ProportionOfDomesticFullPriceTickets: "國內全價票比例",
ProportionOfHotelRoomNightsInAgreement: "協議飯店間夜佔比",
ProportionOfNetTransactionPrice: "成交淨價佔比",
ProportionOfRCTimes: "RC次數佔比",
PullToRefresh: "下拉刷新",
PullUpLoadMore: "上拉加載更多",
Punctuality: "準點率",
Punctuality_x: "準點率%a$",
PurchaseXItemAtMost_x: "限購%a$件",
Purpose: "差旅目的",
PutTheQRCodeIntoTheBox: "將二維碼放入框內，即可自動掃描",
QrCode: "二維碼",
QRCodeHasExpired: "二維碼已失效",
QuantifierHotel: "間",
QuantifierNight: "晚",
QuantifierPeople: "人",
Quantity: "機票張數",
Quarterly: "季度",
QueryConditions: "查詢條件",
QueryNotJurisdiction: "您沒有查詢權限",
QueryQuoteErrors: "查詢報價失敗",
QueryRange: "查詢範圍",
QueryResultOfTheCurrentCity: "當前城市查詢結果",
QueryType: "查詢類型",
QuickSelection: "快速選擇",
R: "退",
RankDescription: "差標描述",
RankInformation: "差標信息",
RankManagement: "差標管理",
RankName: "差標名稱",
Rating: "星(鑽)級",
RatingHighToLow: "星級高-低",
RatingLowToHigh: "星級低-高",
RatingMultipleChoice: "星級(可多選)",
RCContent: "RC內容",
RCManagement: "RC管理",
RCType: "RC類型",
Read: "已讀",
Ready_x: "預計%a$",
Reapply: "重新申請",
ReasonForNotSelectingTheCheapestFlight: "未選擇最低價航班原因",
ReasonForNotSelectingTheLowestPrice: "未選擇最低價原因",
ReasonForTheRefundTicket: "退票類型",
ReasonForTicketIssueFailure: "出票失敗原因",
ReasonForTravelRankingViolation: "違反差旅標準原因",
ReasonsForBusiness: "出差事由",
ReasonsForBusiness_x: "出差事由：%a$",
ReBook: "再次預訂",
ReceiveMode: "接收方式",
RecentSearches: "歷史",
Recharge: "充值",
RechargeAmount: "充值金額",
Recipient: "收貨人",
Recommend: "大家都去",
Recommend_1: "熱門推薦",
RecommendCabinPrice: "為您推薦相同班機的優選價格“",
Recommended: "智能排序",
RecommendedFlights: "推薦班機",
RecommendedTransferFlights: "中轉班機推薦",
RecordsExchange: "兌換記錄",
ReElection: "重選艙位",
RefreshFlight: "刷新航班",
RefreshPrices: "刷新價格",
RefreshSucceed: "刷新成功",
Refund: "退票",
Refund_1: "退 票",
RefundAmount: "退款金額",
RefundAmountPendingApproval: "退款金額待稽核",
RefundCertificate: "退票證明",
RefundChangeAndBaggageInstructions: "退改簽及行李說明",
RefundDetails: "退款明細",
RefundFailed: "退票失敗",
RefundFee: "退票費",
Refunding: "退票中",
RefundInstructions: "退票規則",
RefundOrChangeExplain: "退改說明",
RefundOrderDate: "退票申請日期",
RefundOrderDetails: "退票詳情",
RefundOrderNumber: "退票訂單號",
RefundOrderNumber_x: "退票訂單號%a$",
RefundOrderTime: "退票申請時間",
RefundPassenger: "退票乘機人",
RefundPolicy: "退票規定",
RefundRate: "退票率",
RefundReason: "退票原因",
RefundSucceed: "退票成功",
Regard: "關注",
RegardEmployees: "我關注的人",
RegardFailed: "關注失敗",
RegardSuccess: "關注成功",
Register: "註冊",
Register_1: "註冊資訊",
Register_2: "手機驗證",
Register_3: "等待審核",
RegisterSuccess: "我們將儘快與您聯繫，請保持電話暢通，謝謝！",
Reject: "拒絕",
RelationAccount: "綁定帳號",
RelationLogin: "綁定並登錄",
RelationToHOMSOM: "請綁定登錄帳號",
ReleaseImmediateLoad: "鬆開立即載入",
ReleaseImmediateRefresh: "鬆開立即重新整理",
ReleaseVoice: "鬆開結束",
Relevance: "關聯",
Remaining_1_x: "剩餘%a$件",
Remaining_x: "剩%a$張",
RemainingRoom_x: "僅剩%a$間",
RemainingUseLxd_x: "%a$個(1個旅行豆=1元人民幣)",
Remarks: "備註",
ReminderYouCanImportFrequentTravelerInFrequentTravelerManagement: "溫馨提示：常旅客還可以在常旅客管理中通過導入人員信息來進行添加。",
RemovalFailedPleaseTryAgainLater: "移除失敗，請稍後再試",
RemoveFrequentTraveller: "移除該常旅客",
RemoveFromTheTiedList: "從已綁列表中刪除",
RemoveSuccessful: "移除成功",
RemoveThe_x: "刪除該%a$",
RemovetheCostCenter: "刪除該成本中心",
RemoveTheDepartment: "刪除該部門",
RemoveTheEmployee: "刪除該員工",
RemoveTheNonEmployee: "删除該外部人員",
RemoveTheRC: "刪除該RC",
RemoveThisFrequentContact: "刪除該常用聯絡人",
RenovationYears: "裝修時間",
ReportTotal: "總計",
ReQuery: "重新查詢",
RequiredChoose: "必選",
RequiredChoose_x: "需要選擇%a$人",
RequiredChooseArrivalCityAddressDesc: "(必選)出差途經城市皆需添加",
RequiredChoosePleaseChoose: "(必選)請選擇",
RequiredChooseUneditable: "(必選)不可編輯",
RequiredFill: "必填",
RequiredFillPleaseFill: "(必填)請填寫",
RequiredFillPleaseFillOrChoose: "(必填選)請填寫或選擇",
RequiredFillUneditable: "(必填)不可編輯",
RequiredReadingForBook: "出行必讀",
RequiredReadingForChange: "改期須知",
RequiredReadingForHotel: "訂房必讀",
RequiredReadingForRefund: "退票須知",
ReScan: "重新掃描",
Reschedule: "申請改期",
Rescheduled: "改期",
Rescheduling: "改期行程",
ReschedulingFee: "改期費",
ReschedulingReason: "改期原因",
Reselect: "重新選擇",
Reselect_1: "重選去程",
ReselectFlight: "重新選擇班機",
ReSelectPrice: "重選價格",
Resend: "重新發送",
Resend_x: "重新發送 %a$s",
ResendAfterXSeconds_x: "%a$s 秒後重新發送",
ResendConfirmationMessage: "重新發送確認信息",
ResendPleaseWait: "重新發送請等待",
ResendPleaseWait_x: "重新發送請等待%a$",
Reset: "重置",
ResetFilter: "重置篩選",
ResetPassword: "重置密碼",
ResetUsingBoundEmail: "使用已綁定的郵箱重置",
ResetUsingBoundPhoneNumber: "使用已綁定的手機號重置",
Rest: "休",
Result: "搜尋結果",
RetrieveByEmail: "郵箱找回",
RetrieveByMobile: "手機號找回",
RetrievePassword: "找回密碼",
Return: "返程",
Return_1: "返:",
ReturnAndChangeInstructions: "退改簽說明",
ReturnColon: "返:",
ReturnDate: "返程日期",
ReturnTicket_x: "%a$(返程)",
ReVetting: "重發審批",
RevisedFlightInformation: "變更後的班機資訊：",
ReviseTheDifference: "改期差價",
RideInfo: "乘車信息",
RMB: "元",
RMB_x: "%a$元",
RMBNight: "元/間夜",
RMBPrice_x_x: "¥%a$-%b$",
RMBPriceOver_x: "¥%a$以上",
RMBPriceUnder_x: "¥%a$以下",
RoleDescription: "角色描述",
RoleDetail: "角色詳情",
RoleManagement: "角色管理",
RoleName: "角色名稱",
Room: "客房",
Room_x: "%a$間",
RoomAdult_x_x: "%a$間 %b$成人",
RoomAdult_x_x_x: "%a$間 %b$成人 %c$兒童",
RoomAndNight_x_x: "%a$間 %b$晚",
Roomer: "入住人",
RoomerDetails: "入住人詳情",
RoomerName: "入住人姓名",
RoomFees: "房費",
RoomNight: "間夜",
RoomNightPrice_x_x: "%a$間·%b$晚",
RoomNoXAtLeastChooseOneRoomer_x: "房間%a$至少需要選擇一位入住人",
RoomsAndGuests: "房間及住客",
RoomType: "房型",
RoomTypeDetails: "房型詳情",
RoomTypeRemarks: "房型備註",
RoundTrip: "往返",
Route: "航線",
RouteDepartDateCannotBeLessThanLastRoute_x_x: "第%a$程出發時間不能小於第%b$程出發時間",
RUB: "盧布",
Rules: "規則",
SafetyAlarmRequired: "安全報警必讀",
SafetyManage: "安全管理",
SameCityTransfer: "同城換乘",
SameLevelApprover: "並級審批人",
SameStationTransfer: "同站換乘",
Save: "保存",
Save_1: "省",
Save_2: "保存修改",
Save_3: "節省",
SaveFailed: "保存失敗",
SaveFailedFromCode: "二維碼保存失敗",
SaveOrderError: "保存訂單錯誤，請嘗試退至航班查詢頁面重新選擇！",
SaveQRCode: "保存二維碼",
SaveSucceed: "保存成功",
SaveSucceedToPhotoLibary: "已保存到相簿",
SavingAnalysisofBookinginAdvance: "提前預訂天數節省分析",
SavingAndMissedSaving: "節省分析",
Savings: "節省金額",
SavingsLosses: "節省與損失",
ScaleLevel_1: "1萬-3萬",
ScaleLevel_2: "3萬-10萬",
ScaleLevel_3: "10萬-30萬",
ScaleLevel_4: "30萬-100萬",
ScaleLevel_5: "100萬以上",
ScanAndLogin: "掃碼登錄",
ScanCodeLogin: "掃描二維碼登錄",
ScanSuccessfully: "掃描成功",
ScanTheQRCode: "掃描二維碼",
Schedule: "時刻表",
ScheduledItinerary: "已訂行程",
SchemIndex_x: "方案%a$",
SchemIndexSelect_x: "方案%a$(已選中)",
ScoreByComments: "分",
Search: "查詢",
Search_1: "搜尋",
Search_2: "點擊查詢",
Search_3: "查 詢",
SearchCanBeRescheduled: "査詢可改期班機",
SearchFlight: "機票查詢",
SearchForMoreCities: "搜尋更多城市",
SearchHistory: "搜尋歷史",
SearchHotel: "酒店查詢",
SearchThisArea: "搜尋此區域",
Seat: "席位",
SeatInfo: "選座資訊",
SeatNumber: "座位號",
SeatSelectionDepartingTrip: "選座(去程)",
SeatSelectionReturnTrip: "選座(返程)",
SeatSelectionSucceed: "選座成功",
SecondClass: "二等座",
SecondStep: "第二步",
SecurityAlarmDesc: "遇到危險，請及時110報警",
SecurityAssistant: "安全助手",
SecurityCenter: "安全中心",
SecurityCode: "安全碼",
See: "查看低價",
SeeAll: "查看全部",
SeeAll_x: "查看全部%a$個價格",
SeeDetails: "查看詳情",
Segment: "行程",
SegmentRecommend: "推薦",
Select: "已選",
Select_1_x: "已選：%a$",
Select_x: "選%a$",
SelectAll: "全選",
SelectApprover: "選擇審批人",
SelectCabin: "選擇艙位",
SelectCarPlatform: "選擇用車平台",
SelectCarScene: "選擇用車場景",
SelectCity: "選擇城市",
SelectConfirmationOrder: "選擇確認單",
SelectContact: "選擇聯絡人",
SelectCustomItems: "選擇自定義項目",
SelectDate: "選擇日期",
SelectDeparting: "選去程",
Selected: "選中",
Selected_1: "已選中",
Selected_x_x: "已選擇%a$個%b$",
SelectedAddress: "選擇地點",
SelectedAsGoing: "選為去程",
SelectedFlights: "已選擇航班",
SelectedPassengers: "已选择乘机人",
SelectedTraveler_x: "已選：%a$人",
SelectEmployees: "選擇員工",
SelectFlight: "選擇航班",
SelectFlightChangeInfo: "選擇改期班機資訊",
SelectFlightChangeStep1: "01.選擇改期的乘機人",
SelectFlightChangeStep2: "02.選擇改期的行程",
SelectFlightChangeStep3: "03.選擇改期的日期",
SelectFlightOnline: "選擇值機行程",
SelectFlightRefund: "選擇退票行程",
SelectFlightRefundInfo: "選擇退票班機資訊",
SelectFlightRefundStep1: "01.選擇退票的乘機人",
SelectFlightRefundStep2: "02.選擇退票的行程",
SelectFlightRescheduling: "選擇改期行程",
SelectFromTheAlbum: "從相冊中選擇",
SelectInvoicingType: "選擇發票類型",
Selection: "選座",
SelectionResult: "選座結果",
SelectLanguage: "選擇語言",
SelectOneWay: "選單程",
SelectPassengers: "選擇乘客",
SelectReturn: "選返程",
SelectTheNumberOfRooms: "選擇間數",
SelectThePassenger: "選擇乘機人",
SelectTheReasonForTheRefundTicket: "選擇退票類型",
SelectTheRescheduleType: "選擇改期類型",
SelectTrain: "選擇車次",
SelectYourCabin: "艙位選擇",
SelectYourSeat: "選擇",
SelectYourSeatDeparting: "去程選擇",
SelectYourSeatReturn: "返程選座",
Self: "本人",
SelfPay: "自付",
Send: "發送",
SendATo12306: "發送至12306",
SendDistressMessage: "發送求救簡訊",
SendEmailOrPhone: "發送至郵箱/手機",
SendFailure: "發送失敗",
SendingLXD: "送旅行豆",
SendMail: "發送郵件",
SendMailSuccessfullyPleaseCheckTheMailboxToResetThePassword: "發送郵件成功，請檢查郵箱以重設密碼",
SendMoneyToTheFollowingAccount: "匯款至以下帳戶",
SendSMS: "我們已經向",
SendSMS_1: "發送了一條驗證簡訊，請輸入您收到的驗證碼。",
SendSMS_2: "發送簡訊",
SendSMS_x: "我們已經向%a$發送了一封驗證簡訊，請輸入您收到的驗證碼。",
SendSucceed: "發送成功",
SendTo12306_x: "發送 %a$ 至 12306",
SendVerificationCode: "發送驗證碼",
SendVerificationCode12306: "請發送666至12306",
SendVerificationCodeTo12306ByMobilePhoneUpToTimesPerDay_x_x: "用手機%a$發送驗證碼%b$至12306，每天最多發送3次",
SerialNumber: "流水號",
Service: "客服",
Service_1: "轉人工",
ServiceAgreement: "服務協議",
ServiceFee: "服務費",
ServiceFeePerPerson_x: "服務費 %a$/人",
ServiceLife: "使用有效期",
SetAsDefaultContact: "設為預設聯絡人",
SetAsStaff: "設為員工",
SetEmergencyContact: "設置緊急聯絡人",
SetNewPassword: "設置新密碼",
SetSuccessfully: "設置成功",
Setting: "設定",
SettlementMethod: "結算方式",
SettlementPeriod: "結算帳期",
SettleNow: "現結",
SGD: "新加坡元",
ShareDescPaymentOnBehalf_x: "我在恒順商旅預訂了%a$，需要你帮我付款哦~",
SharedFlight: "共享航班",
SharePDF: "分享PDF",
ShareTitlePaymentOnBehalf_x: "%a$希望你幫他付款",
ShareWithFriends: "分享給好友",
ShippingAddress: "收貨地址",
ShowAvailableTicketsOnly: "只看有票",
ShowMoreSuppliers: "查看更多服務商",
ShowTheFrontOfYourFace: "展示臉部正面",
SigningPolicy: "簽轉規定",
SignOut: "退出當前帳戶",
SignOut_1: "退 出 當 前 帳 戶",
Skip: "跳過",
SlideUpToSeeMore: "上滑查看更多",
SlideYourFingerUp: "手指上滑，取消發送",
Smoke: "吸煙",
SMS: "短信核驗",
SMSVerification: "短信驗證",
Snatch: "搶",
SnatchDeadline_x: "發車前%a$",
SnatchDeadlineHour_x: "發車前%a$小時",
SnatchDeadlineMinute_x: "發車前%a$分鐘",
SnatchTicket: "搶票",
SnaTchTicketDetails: "搶票詳情",
SoldOut: "已售罄",
SorryCantFind: "對不起，找不到",
SorryCouldNotFindIt_x: "抱歉，沒有找到“%a$”相關的內容",
SorryNoCabinAvailable: "很抱歉，暫無艙位",
SorryNoMatchingFlight: "抱歉，無符合要求的航班！",
SorryNoMatchingHotel: "抱歉，無符合要求的飯店！",
SorryNoMatchingRoom: "抱歉，無符合要求的房型！",
SorryNoMatchingTrain: "抱歉，無符合要求的車次！",
SorryThePageYouVisitedDoesNotExist: "非常抱歉，您訪問的頁面不存在。",
SorryThereAreNoSearchResultsFor_x: "抱歉，沒有 %a$ 的搜尋結果。",
Sort: "排序",
SpecialLineForTravelConsultants: "差旅顧問專線",
SpecialOffer: "特價",
SpecificRequirements: "特殊需求",
Spending: "支出",
Staff: "員工",
StaffAdd: "新增員工",
StaffNo: "員工編號",
StarComfort: "三星(鑽)",
StarComfort_1: "三鑽",
StarEconomic: "二星(鑽)及以下",
StarEconomic_1: "二鑽及以下",
StarLuxury: "五星(鑽)",
StarLuxury_1: "五鑽",
StarPremium: "四星(鑽)",
StarPremium_1: "四鑽",
StarRating: "星級",
Start: "開始",
StartDate: "開始日期",
StationDropOff: "送車站",
StationDropOff_x: "%a$送車站",
StationName: "車站名稱",
StationPickup: "接車站",
StationPickup_x: "%a$接車站",
Stay: "停留",
Stay_1_x_x: "%a$停留%b$",
Stay_2_x_x: "轉機%a$ 停留%b$",
Stay_3_x_x: "經停%a$ 停留%b$",
Stay_x: "停留%a$",
Stayed: "曾到過",
Stop: "經停",
Stop_1: "停",
Stop_1_x: "%a$停",
Stop_2: "經停",
Stop_2_x: "%a$個經停",
Stop_x: "經停%a$",
StopBy_x: "經 %a$",
StopInfo: "經停資訊",
StopsFor_x: "停留%a$",
StowMoreSuppliers: "收起更多供應商",
SubmissionTime: "提交時間",
Submit: "提交",
Submit_2: "提 交",
SubmitAndPay: "提交並支付",
SubmitOrder: "提交訂單",
SubmitOrderForChange: "提交改期訂單",
SubmitSucceed: "提交成功",
Submitted: "已提交",
SubmittedRefund: "提交退票",
SubmitVetting: "提交審批",
Subsidiary: "積分明細",
SubwayStation: "地鐵站",
SuccessfullySaved: "保存成功",
SuccessfulPasswordModification: "修改密碼成功",
SuperEconomyClass: "超級經濟艙",
Superior_x: "上級%a$",
SuperiorCostCenter: "上級成本中心",
SuperiorDepartment: "上級部門",
SupportingMixedPayment: "支持超標付",
Surname: "姓(拼音)",
SurnameEgLI: "如：HAN",
Surplus: "剩餘",
SwimmingPool: "游泳池",
SwipeVerification: "滑動驗證",
SwitchAccount: "切換帳號",
SwitchLogin: "帳號密碼登入",
SyncDate: "同步日期",
SystemApproval: "系統審批",
SystemPermissionCamera: "您沒有相機授權，請在設定中打開授權",
SystemPermissionDevice: "您沒有授權讀寫設備，請在設定中打開授權",
SystemPermissionInstall: "安裝此應用需要手動授權，請去設定中開啟權限",
SystemPermissionLocation: "您沒有授權定位，請在設定中打開授權",
SystemRecommendedPrice: "系統推薦價格",
SystemRole: "系統角色",
TakeAPicture: "拍照",
TakeOffTimeAnalysis: "起飛時間段分析",
TakesOff: "起飛",
TakesOff_x: "%a$起飛",
TakesOffArrival_x_x: "%a$到達, %b$起飛",
TalkAboutYourSuggestionOrProblem: "說說你的建議或遇到的問題",
Tax: "稅",
Tax_1: "稅",
Taxi: "打車",
TaxpayerIDNumber: "納稅人識別號",
TaxPrice_x: "稅%a$",
TaxRegisterAddress: "稅務登記地址",
TeamLink: "團隊連結",
TeamQRcode: "團隊二維碼",
Telephone: "電話",
TemplateBound: "已綁定其他模板",
TemplateName: "模板名稱",
TemplateNotes: "模板備註",
ThankYouForYourFeedback: "感謝您的回饋",
THB: "泰銖",
TheAgeOfTheChild_x: "第%a$位兒童年齡",
TheApplicationFormYouHaveSelectedNotSupportRoundTripSwitching: "您選擇的申請單不支援來回切換",
TheCarMayChangePleaseCheckAgain: "車輛資訊可能有變，請重新查詢",
TheCarryingRegulationsFor: "攜帶鋰電池和充電寶的乘機規定、",
TheCityHasBeenSelected: "已選擇該城市",
TheCodeValueIsEmpty: "code值為空",
TheCurDatCanExcTheNexTriDatPleGoBacToTheHomPagToModify: "當前日期不能超過下一行程日期，請返回首頁修改",
TheCurrentDeviceIsLockedOrNotBiometricEnabled: "當前設備被鎖定或沒有開通生物識別功能",
TheCurrentDeviceSupportsFaceID: "當前設備支援臉部辨識登入，是否開通？",
TheCurrentDeviceSupportsTouchID: "目前的設備支援指紋登錄，是否開通？",
TheCurrentNetworkIsNotAvailable: "目前網絡無法使用",
TheCurrentNetworkIsNotAvailable_1: "目前網絡無法使用，請檢查連線狀態",
TheCurrentOrderHasBeenSplitIntoMultipleOrdersPleaseGoToTheOrderListToView: "目前訂單已拆分為多個訂單，請前往訂單列表查看",
TheCurrentOrderIsAPersonalPaymentOrder: "目前訂單為個人支付訂單，審批通過後請儘快支付進行出票",
TheCurrentOrderRequiresPersonalPayment1: "目前訂單需個人支付，待支付完成後，才能進行出票。",
TheCurrentOrderRequiresPersonalPayment2: "目前訂單需個人支付，待支付完成後，才能進行確認。",
TheCurrentPointsAreNotEnoughToExchangeThisItem: "目前積分不足以兌換此商品",
TheCurrentServiceNeedsToUseLocation: "目前服務需使用定位功能，請前往設定開啟",
TheCurrentTimeIsNotAvailableForAppointments: "目前時間不在可預約的範圍內",
TheDateOfDepartureCannotBeEarlierThanThePreviousProcess_x: "%a$出發日期不能超過上一個行程",
TheDriverIsGoingToTheDestination: "司機正在前往目的地",
TheDriverWillContactThePassenger: "司機將與該乘車人聯繫",
TheEmailNotificationHasBeenTurnedOn: "已開啟郵件通知功能，郵箱地址不能為空！",
TheFlightCombinationYouSelectedCannotBeReserved: "您選擇的航班組合無法預訂，請分開下單",
TheFlightHasInsufficientTickets: "該航班餘票不足，請選擇其他航班",
TheFollowingTimesAreLocalTime: "以下時間均為當地時間",
TheIdentityOfPassengerXHasNotBeenVerified_x: "乘車人(%a$)身份信息待核驗",
TheItineraryYouSelectedNotMatchTheApplicationForm: "您選擇的行程與申請單行程不匹配",
TheLengthOfTheViolationOfTheTravelRankCannotExceedXWords: "違反差旅標準原因長度不可超過50個字",
TheLowestLogicalFareIsSuggestedAccordingToTheTravelPolicyOfYourCompany: "根據貴司規定，推薦您預訂最低價航班",
TheLowestPriceForDomesticAirTicketIsNotSelected: "國內機票未選擇最低價，需要選擇的原因",
Theme: "特色",
TheNetworkSignalIsPoor: "網絡不給力，請稍後再試試吧~",
TheNewPassword: "新密碼",
TheNewVersionIsOnlinePleaseUpdateToTheLatestVersion: "新版本已上線，請更新至最新版本。",
TheNextPageIsNotUnderOuControl_x: "下個頁面不受我們控制哦，使用時請注意安全%a$",
TheNumberOfTheTicket: "取票號",
TheOrderConfirmedAndProofOfSuccessfulBookingFollowingMethods: "訂單已確認，可通過以下方式獲得預訂成功證明。",
TheOrderIncludesAirfare: "點擊下一步，表示已閱並同意關於",
TheOrderIncludesRescheduledPassengers: "該訂單中有乘客已改期",
TheOrderIsForIndividualPaymentOrders: "該訂單為個人支付訂單，改期業務請聯繫您的差旅顧問或致電: 4006-123-123",
TheOrderIssuedAndProofOfSuccessfulBookingFollowingMethods: "訂單已出票，可通過以下管道獲取機票確認單。",
ThePeriodOfValidity: "有效期",
ThePeriodOfValidityTips: "例：2025年6月，請輸入0625",
ThePhoneNumberIsTheSameAsTheCurrentlyBoundMobilePhoneNumber: "該手機號與當前綁定的手機號相同",
TheRailwayBureauStipulatesThatTicketsMustBeSoldByRealName: "鐵路局規定購票必須實名制",
ThereAreOtherFlightsAtTheSameTime: "同時段已有其他航班，是否繼續?",
TheReasonForExceedingTheStandardCannotExceedXWords: "超標原因長度不可超過50個字",
TheRefundFeeIsBeingApprovalByTheFlightCompany: "退票費航司審核中",
TheRefundFeeIsSubjectToTheReviewOfTheAirlineCompany: "退票費具體以航司審核為準",
ThereMeals: "有餐食",
ThereMustBeNoContactWithTheSamePhoneNumber: "不得存在聯繫手機號相同的聯繫人",
ThereNoAvailableRoomTypesForBooking: "暫無可預訂房型",
ThereNoAvailableRoomTypesForBookingForChangeFilter: "沒有找到符合條件的房型，您可以更改篩選項試試~",
TheRescheduleType: "改期類型",
TheReschedulingFeeIsBeingApprovalByTheFlightCompany: "改期費航司審核中",
TheReschedulingFeeIsSubjectToTheReviewOfTheAirline: "改期費具體以航司審核為準",
TheRoomerIDNumberCannotBeRepeated: "入住人證件號碼不能重複",
TheRoomIsReservedAllNight: "房間整晚保留, 14:00前到店可能需要等房",
TheSpecificPriceIsSubjectToTheReview: "*具體價格以12306審核為準",
TheSpecificRefundAndChangeRulesShallBeSubjectToTheDateOfTheTicketIssue: "具體退改簽規則以航司審核為準！",
TheSubmissionFailedPleaseTryAgainLater: "提交失敗, 請稍候再試!",
TheSysteIsBusyPleaseReOperate: "系統繁忙, 請重新操作!",
TheSystemIsBusyPleaseTryAgainLater: "系統繁忙，請稍後再試！",
TheTakeoffAndLandingLocalTime: "班機起降時間均為當地時間",
TheTicketYouChooseIsVeryCloseToTheDepartureTime: "您選擇的列車距開車時間很近了，請確保有足够的時間辦理安全檢查、實名制驗證及檢票等手續，因臨近出發，會有出票失敗風險，請您留意確認資訊，以免耽誤您的行程。",
TheTicketYouChooseIsVeryCloseToTheDepartureTimeChange: "您選擇的列車距開車時間很近了，請確保有足够的時間辦理安全檢查、實名制驗證及檢票等手續，因臨近出發，會有改簽失敗風險，請您留意確認資訊，以免耽誤您的行程。",
TheValidityPeriodHasExpired: "有效期已過期！",
TheValidityPeriodHasExpiredByReplace: "您的證件已經過期，請更換其他證件或延期後重新預訂",
TheVerificationCodeHasBeenSentTo_1_x: "驗證碼已發送至 %a$",
TheVerificationCodeHasBeenSentTo_x: "已發送驗證碼至%a$",
ThirdPartyBinding: "第三方帳號關聯",
ThirdPartyRelation: "帳號關聯",
ThirdStep: "第三步",
ThisInformationIsForReferenceOnlyIfThereIsAnyChangePleaseReferToTheStationAnnouncement: "*該資訊僅供參考，如有變化，請以車站公告為準",
ThisOrderUseLxd_x: "本單您可使用%a$個旅行豆",
ThJourneyIsXAndIsExpectedToTakeXMinutes_x_x: "全程%a$公里，預計行駛%b$分鐘",
TicketAmount: "出票金額",
TicketBarrier: "檢票口",
TicketBarrierWaitingPlace: "檢票口/候車地點",
TicketBookingAuthority: "國內機票差標",
TicketChangeRefund: "退改簽情況",
TicketFare: "票價",
TicketingMail: "出票郵件",
TicketingSMS: "出票簡訊",
TicketingSucceed: "出票成功",
TicketNumber: "票號",
TicketPricesHaveChanged: "機票價格發生變更",
TicketRestrictions: "退改簽",
TicketRestrictions_x: "退改%a$起",
TicketRestrictions_x_x: "退改%a$%b$起",
TicketRestrictionsArrow: "退改簽 >",
TicketRestrictionsCancellationBaggage: "退改簽 行李",
TicketRestrictionsCancellationBaggageArrow: "退改簽 行李 >",
TicketRestrictionsRule: "退改簽規則",
Tickets: "張",
Tickets_1: "/張",
Tickets_x: "%a$張",
TigerRobot: "小虎機器人",
Time: "兌換時間",
Time_1: "時間",
TimeArrival: "到時",
TimeConsuming: "耗時",
TimeConsumingLeast: "耗時從少到多",
TimeConsumingMost: "耗時從多到少",
TimeDeparture: "發時",
TimeHighToLow: "時間 晚-早",
TimeLowToHigh: "時間 早-晚",
TimeRange: "時間範圍",
Tips: "提示",
Tips_1: "[提示]",
Tips_2: "溫馨提示",
TipsColon: "溫馨提示：",
TMS: "恒順酒店TMS",
TMSOnline: "恒順酒店TMS線上",
To: "至",
ToApply: "去申請",
ToBeConfirmed: "待確認",
ToBetterYourBusinessTripExperience: "為了讓您的出差享受更優質的飯店",
ToChoose: "去選擇",
Today: "今天",
ToEnsureThatThePassengerInformationIsCorrect: "為保證乘車人資訊正確，請按提示進行核驗",
ToEnsureTheSecurityOfTheAccount: "為保障帳戶安全，請確認是本人操作",
ToGuarantee: "去擔保",
ToGuaranteeAmount: "擔保金額",
Tomorrow: "明天",
Tomorrow_1: "後一天",
Tomorrow_x: "次日%a$點",
Top5Cities: "前五個城市",
Top5CostCenterExpense: "前五成本中心消費情況",
Top5DeptsExpenses: "前五部門消費分析",
Top5DestinationsbyExpense: "前五目的地消費情況",
TopFiveModels: "前五車型消費分析",
ToSetUp: "去設置",
Total: "合計",
Total_1: "往返總價:",
Total_2: "合計：",
Total_3: "往返總價",
TotalAmount: "總金額",
TotalAmount_x: "總金額%a$",
TotalAmountOfCarUsed: "用車總金額",
TotalAmountOfTrainTicket: "火車票總金額",
TotalAmountPendingApproval: "總金額待稽核",
TotalBudget: "總預算",
TotalBudgetAmount: "預算總金額",
TotalBudgetRMB: "總預算(元)",
TotalCabinInfo_x: "共%a$個航班資訊",
TotalDuration: "總時長",
TotalDuration_x: "總時長%a$",
TotalMileage: "總里程",
TotalMissedSaving: "總體損失",
TotalNights: "%a$晚",
TotalNights_1: "共%a$晚",
TotalNumberOfPeople: "總人數",
TotalOfDay_x: "%a$天",
TotalOrderAmount: "訂單總金額",
TotalPiece_x: "共%a$件",
TotalPrice: "總價",
TotalPrice_x: "總價%a$",
TotalPriceIncludingTax: "包含稅/費",
TotalPriceNoTax: "不含稅價",
TotalPriceNoTax_1: "往返總價(不含稅)",
TotalPriceNoTax_2: "多程總價(不含稅)",
TotalPriceTax: "含稅價",
TotalPriceTax_1: "往返總價(含稅)",
TotalPriceTax_2: "多程總價(含稅)",
TotalSaving: "總體節省",
TotalTrip_x: "(共%a$程)",
ToTravel: "待出行",
TouchIDIsNotEnabled: "當前設備沒有開啟指紋ID",
TouchIDIsOnlyValidForTheMachine: "指紋ID僅針對本機有效",
TouchIDLogin: "指紋登入",
TourismConference: "旅遊",
TrackingNo: "快遞單號",
TrackingNumber: "追蹤號碼",
Traffic: "周邊交通",
Train: "火車",
TrainAccount: "12306帳號",
TrainAccountLogin12306: "登入12036",
TrainAccountName: "帳戶名",
TrainAccountPwd: "帳戶密碼",
TrainAccountPwd12306: "12306帳號密碼",
TrainAccountSubmitCheck: "提交核驗",
TrainApplication: "火車票申請",
TrainAvailable: "有",
TrainBookPermission: "火車票預訂權限",
TrainChanged: "車次資訊可能有變，請重新查詢",
TrainNumber: "車次",
TrainOrders: "火車票訂單",
TrainPassenger: "乘客",
TrainRank: "火車票差標",
TrainSnatchCode: "備選車次",
TrainSnatchDate: "備選日期",
TrainSnatchDeadline: "截止時間",
TrainSnatchSeats: "備選座席",
TrainStation: "火車站",
TrainTicket: "火車票",
TrainTicketConfirmation: "火車票訂單確認",
TrainTicketFare: "火車票票價",
TrainTicketsBooking: "預訂火車票",
TrainTicketSmsVerification_x_x_x: "請您通知乘車人(%a$)使用手機號碼%b$在30分鐘內發送短信%c$至12306進行核驗。",
TrainTicketsYouSearchedFor: "你搜尋過的火車票",
TrainType: "車次類型",
TrainUnavailable: "無",
Transfer: "中轉",
Transfer_1: "轉",
Transfer_1_x: "%a$轉",
Transfer_2: "中轉",
Transfer_2_x: "%a$程中轉",
Transfer_Stop_x_x: "%a$程中轉%a$個經停",
Transfer_x: "中轉%a$",
TransportationAccommodation: "交通住宿",
TransportTime: "用車時間",
TravelApplication: "出差申請單",
TravelApplicationCode: "出差申請單號",
TravelBooking: "差旅預訂",
TravelCard: "常旅卡",
TravelControl: "差旅管控",
Traveler: "出行人",
Traveler_1: "出差人員",
Traveler_x: "出差人員：%a$",
TravelerDetails: "出行人詳情",
TravelerType: "旅客類型",
TravelPolicy: "差旅標準",
TravelPositioning: "差旅定位",
TravelPurpose: "差旅目的",
TravelReport: "差旅報表",
TravelSecurity: "出行保障",
TravelStandard: "差標",
TravelTime: "出行時間",
TravelType: "差旅類型",
Trip: "行程",
TripType: "行程類型",
TryAgain: "再試一次",
TryAgain_1: "再試一次",
TWD: "台幣",
TwinBed: "雙床",
Type: "類型",
Type_1: "原因類型",
TypeOfQuestionRequired: "問題類型(必填)",
TypeOfTrip: "旅行類型",
UnableToBookTheCurrentAgreementPriceProduct: "很抱歉，您的訂單中的乘機人不在航司白名單，囙此無法預訂當前協定價產品。",
UnableToLocateCurrentCity: "無法定位當前城市",
Unbind: "去綁定",
UnbindSuccessfully: "解綁成功",
Unbound: "未綁定",
UnboundApprover: "未綁定指定審批人",
UnboundCustomization: "未綁定自訂項目",
UnboundEmployees: "未綁定員工",
UnboundTemplate: "未綁定範本",
UnderOneYearOld: "1歲以下",
UnderTheCurrentCostCenterNoCostCenter: "當前成本中心下暫無子成本中心",
Uneditable: "不可編輯",
Unfilled: "未填寫",
Unfinished: "未結束",
Unfold: "展開",
UnHandleAuthorization_x: "您有待處理的申請單(%a$)",
UnhookYourFingersAndCancelSend: "鬆開手指，取消發送",
Unit: "單位",
Unit_1: "個",
Unit_1_x: "%a$份",
Unit_x: " x %a$份",
Unit_x_x: "%a$ x %b$份",
UnitAbbreviation: "單位簡稱",
UnitLogo: "單位logo",
UnitManagement: "單位管理",
UnitName: "單位名稱",
UnitNumber: "單位編號",
UnitNumber_x: "單位編號：%a$",
UnitNumberOrUnitName: "單位編號或單位簡稱",
Unknown: "未知",
Unselected: "未選擇",
UnStaffAdd: "新增外部人員",
Unsubmitted: "未提交",
Untie: "解綁",
UnusedTicketTitle_x: "乘機人：%a$有以下行程的未使用機票，您可以通過改期來預訂當前行程，如果需要預訂可以點擊【去改期】按鈕進行操作。",
Update: "馬上升級",
Update_1: "去更新",
UpdateSearchWhenPullingMap: "拉動地圖時更新搜索",
Upgrading: "升級中",
UploadCertificate: "上傳證明",
UploadedCertificate: "已上傳證明",
UploadFile: "上傳檔案",
Upscale: "高檔",
USD: "美元",
UseCar: "用車",
UsedForCheckingInAtTheHotelFrontDeskOrForProcessingDestinationVisas: "用於在酒店前台辦理入住，或辦理目的地簽證時使用",
UseLxd: "使用",
UseLxd_x: "最高可使用%a$旅行豆",
UserCanceled: "使用者取消",
UserName: "用户名",
UserNameEmailMobile: "12306使用者名稱/電子郵件/手機號碼",
UseRules: "使用細則",
UseTheCar_x: "%a$ 用車",
UseTheCarImmediately: "立即用車",
UsingTheLastestVersion: "您使用的已是最新版本",
ValidationCertificateInformationNotPassed_x: "%a$證件資訊核驗未通過",
ValidPeriod: "有效期至",
VehicleDisinfection: "車輛消毒",
VehicleTrack: "用車軌跡",
VerificationCode: "驗證碼",
VerificationCodeAgain: "重新獲取驗證碼",
VerificationCodeCheck: "輸入簡訊驗證碼，完成核驗",
VerificationCodeCheckFail: "驗證碼校驗失敗",
VerificationCodeCoped: "驗證碼已複製",
VerificationCodeCopiedSuccessful: "驗證碼複製成功",
VerificationCodeFailedToSend: "驗證碼發送失敗",
VerificationCodeGet: "獲取驗證碼",
VerificationCodeHasBeenSent: "驗證碼已發送",
VerificationSucceeded: "校驗成功，通過！",
VerifyUsingMail: "使用郵箱驗證",
VerifyUsingPhoneNumber: "使用手機號驗證",
VersionNumber: "版本號",
VersionUpdate: "版本更新",
ViewAllStaff: "可查看全部員工",
ViewBookPermissions: "查看/預訂許可權",
ViewComments: "查看評論",
ViewConfirmNumber: "查看確認單",
ViewDesignated_x: "可查看指定%a$",
ViewDesignatedDepart: "可查看指定部門",
ViewDesignatedStaff: "可查看指定員工",
ViewDirectTicket: "查看直達車票",
ViewElectronicPolicy: "查看保單",
ViewItineraryTrack: "查看行程軌跡",
ViewMoreTransferSchemes: "查看中轉方案",
ViewOnlySelf: "僅限查看本人",
ViewOrder: "查看",
ViewOtherPrices_x: "查看其他%a$個價格",
ViewPermissions: "查看權限",
ViewRoomType: "查看房型",
ViewTheBigMap: "查看大圖",
ViewTheLowestPrice: "查看最低價航班",
ViewTravelApplicationForm: "查看出差申請單",
ViolationOfBusTravelRank: "違反汽車票差旅標準，需要選擇的原因",
ViolationOfCarTravelRank: "違反用車差旅標準，需要選擇的原因。",
ViolationOfHomsomTravelStandardsNotSelectable: "違反貴司的差旅標準，不可選擇",
ViolationOfHomsomTravelStandardsSelectedReason: "違反貴司的差旅標準，需選擇原因：",
ViolationOfHotelTravelRank: "違反酒店差旅標準，需要選擇的原因",
ViolationOfMealsTravelRank: "違反用餐差旅標準，需要選擇的原因",
ViolationOfRank: "違反差標",
ViolationOfRankDetail: "違反差標詳情",
ViolationOfRankReason_x: "違反差標原因：%a$",
ViolationOfTrainTravelRank: "違反火車票差旅標準，需要選擇的原因",
ViolationOfTravelRank: "違反差旅標準",
VoidApplicationForm: "作廢申請單",
VoidedFailed: "作廢失敗",
VoidedSuccessfully: "作廢成功",
VoluntaryRefund: "自願退票",
VoluntaryRefundDesc: "自願退票(按航空公司退改規則收費)",
VoluntaryRefundExplain: "自願退票說明",
VoluntaryReschedule: "自願改期",
VoluntaryRescheduleDesc: "自願改期(按航空公司退改規則收費)",
VoluntaryRescheduleExplain: "自願改期說明",
Voucher: "抵用券",
WaitingForConfirmation: "等待確認",
WarmTipsWhitelistedEmployees: "溫馨提示：白名單內員工，無需審批。",
WarmTipTheModificationIsNotSupportedForTheTimeBeing: "溫馨提示：暫不支援修改，如需修改請聯繫您的專屬顧問",
Wechat: "微信",
WeChat_Limit_1: "因小程式限制，請在恒順商旅APP或網站中進行該操作",
WechatByAccount: "企業微信帳號",
WechatByUser: "企業微信員工綁定",
WechatIsNotInstalled: "微信未安裝",
WechatNotSupportDDPleaseUseAppOrH5: "微信小程式暫不支援滴滴用車，請使用app或h5預定",
WechatNotSupportOnlineCheckInPleaseOpenOnPcOrApp: "微信小程式暫不支援線上值機，請使用pc、app或h5打開",
WechatPay: "微信支付",
WeekFri: "五",
WeekFri_1: "週五",
WeekMon: "一",
WeekMon_1: "週一",
WeekSat: "六",
WeekSat_1: "週六",
WeekSun: "日",
WeekSun_1: "週日",
WeekThur: "四",
WeekThur_1: "週四",
WeekTues: "二",
WeekTues_1: "週二",
WeekWed: "三",
WeekWed_1: "週三",
Welcome: "Hi，歡迎您！",
WelcomeToHOMSOM: "歡迎來到恒順商旅",
WelcomeToHumanCustomerService: "歡迎進入人工客服",
WelcomeToLogin: "您好，歡迎登錄。",
WhetherToAcceptTheSeatlessTicket: "當所選座位餘票不足時，是否接受無座票",
WhetherToConfirmTheDeletionOfThisFavoriteContact: "確認刪除該常用聯繫人",
WhetherToConfirmThePasswordChange: "是否確認修改密碼？",
WhetherToConfirmTheRefund: "是否確認退票?",
WhetherToSendARefundEmail: "是否發送退票郵件",
WhetherToSendARefundEmail_1: "發送退票郵件",
WhetherToSendARefundSuccessfulMail: "是否發送退票成功郵件",
WhetherToSendARefundSuccessfulMail_1: "發送退票成功郵件",
WhetherToSendARefundSuccessfulSMS: "是否發送退票成功簡訊",
WhetherToSendARefundSuccessfulSMS_1: "發送退票成功簡訊",
WholeRoute_x: "全程%a$",
Window: "窗",
WindowType: "窗戶",
Withdrawal: "提現",
Work: "班",
WriteReasonCode: "填寫ReasonCode",
XFormatIsIncorrect_x: "%a$格式不正確",
XHoursBeforeAndAfterTheLowestPrice_x: "前後%a$小時最低價",
XIDIsWrong_x: "(%a$)證件信息校驗不通過",
XIsUnderXyearsOld_x: "%a$未滿12周歲",
XOfFlight_x: "飛行%a$",
XPeoplePerRoom_x: "每間可住%a$人",
XTrips_x: "共%a$車次",
Year: "年",
YearOnYear: "同比",
Yesterday: "前一天",
You: "您",
YouAlsoHaveUnselectedPassengers: "您還有未選座的乘客，請選座完成後再提交訂單！",
YouCanDoTheFollowing: "您可以進行以下操作",
YouCannotSelectContactsWithTheSamePhoneNumberAtTheSameTime: "不得同時選擇手機號相同的聯繫人",
YouCanOnlyGetSMSVerificationCodesFor3MobilePhoneChecksPerDay: "每天僅能獲取3次手機核驗的短信驗證碼",
YouCanOpenItInMySettingSecurityManager: "您可在我的-設置-安全管理中開通",
YouCanUseFaceIDVerification: "開啟後，可使用面容ID驗證，快速完成登錄",
YouCanUseTouchIDVerification: "開啟後，可使用指紋ID驗證，快速完成登錄",
YouHaveACarOrderInProgress: "您有一個用車訂單正在進行",
YouHaveToDoFaceRecognitionAlone: "必須一個人進行人臉識別",
YouHaveUnselectedPassengersPleaseSubmitAfterSeatSelection: "您有未選座的乘客，請選座完成後再提交",
YourCompanyAllowsYouToPayTheExtraAmountForAnOutPolicyHotelByCreditCardWeChatOrAlipay: "公司允許超額部分個人補貼，可用微信、支付寶支付超標部分。",
YourCurrentLoginAccountNeedsMobilePhoneNumberVerification: "您當前登錄的帳號需進行手機號核驗",
YourDateOfBirthGenderNationalityAndIDCardDoNotMatch: "您的出生日期和性別國籍與身份證不符，已為您更正",
YourExclusiveTravelConsultant: "您的專屬差旅顧問",
YourHotelGroupMembershipCard: "您的飯店集團會員卡",
YourOrderHasNotBeenCompletedOut: "您的訂單尚未完成，是否確定要離開當前頁面?",
YourOrderHasNotBeenFilledOut: "您的訂單尚未填寫完成，是否確定要離開當前頁面?",
YourPendingApplicationForm_x: "您有待處理的申請單(%a$)",
YourStayIsTooLongTheFlightAndPriceInformationMayChangeAndTheLatestPrice: "您的停留時間過長，航班及價格資訊可能發生變動，正在為您查詢最新價格",
YourTravelCard: "您的常旅卡",
YouWill: "您將從",
YuanStart_x: "%a$元起",
}