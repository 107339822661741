
// import message from './message/index'
import chinese from './zh-cn'
import english from './en-us'
import taiwan from './zh-tw'
import Japanese from './ja-jp'
const message = {
  CN: chinese,
  EN: english,
  TC: taiwan,
  // HK: hongkong,
  JAP: Japanese
}
// import {reformIntervals} from 'echarts/src/util/number';
const i18N = function(keys, type, formats = ['yyyy-MM-dd', 'MM dd, yyyy']) {
  if (!keys) return
  let language = window.localStorage.language || 'CN'
  if (type === 'date') {
    // 当type为date时, keys必须是 yyyy-MM-dd 的格式
    return switchToEnglishDateFormat(keys, formats[language === 'EN' ? 1 : 0])
  } else if (type === 'function') {
    let res = message[language]
    let list = ['a', 'b', 'c', 'd', 'e', 'f', 'g']
    let val = res[keys] || ''
    formats.map((m, i) => {
      let reg = new RegExp('%' + list[i] + '\\$', 'g'); //
      val = val.replace(reg, m)
    })
    return val
  } else {
    let res = message[language]
    return res[keys] || ''
  }
}
// 日期字符串转英文日期字符串
const switchToEnglishDateFormat = function(date, format) {
  let datelist = date.split('-')
  let week = getWeekAbbreviation(new Date(datelist[0], datelist[1] - 1, datelist[2]).getDay())
  let year = datelist[0]
  let month = getMonthAbbreviation(datelist[1])
  let day = datelist[2]
  let res = format.replace('yyyy', year).replace('MM', month).replace('dd', day).replace('week', week)
  return res
}
// 月份数值转英文月份缩写
const getMonthAbbreviation = function(month) {
  let language = window.localStorage.language || 'CN'
  if (language === 'CN' || language === 'TC' || language === 'JAP' || language === 'HK') {
    return month
  } else if (language === 'EN') {
    switch (Number(month)) {
      case 1:
        return 'Jan'
      case 2:
        return 'Feb'
      case 3:
        return 'Mar'
      case 4:
        return 'Apr'
      case 5:
        return 'May'
      case 6:
        return 'Jun'
      case 7:
        return 'Jul'
      case 8:
        return 'Aug'
      case 9:
        return 'Sep'
      case 10:
        return 'Oct'
      case 11:
        return 'Nov'
      case 12:
        return 'Dec'
      default:
        return ''
    }
  }
}
// 星期数值
const getWeekAbbreviation = function(week) {
  let language = window.localStorage.language || 'CN'
  if (language === 'CN' || language === 'TC' || language === 'JAP' || language === 'HK') {
    switch (Number(week)) {
      case 0:
        return '周日'
      case 1:
        return '周一'
      case 2:
        return '周二'
      case 3:
        return '周三'
      case 4:
        return '周四'
      case 5:
        return '周五'
      case 6:
        return '周六'
      default:
        return ''
    }
  } else if (language === 'EN') {
    switch (Number(week)) {
      case 0:
        return 'Sun'
      case 1:
        return 'Mon'
      case 2:
        return 'Tues'
      case 3:
        return 'Wed'
      case 4:
        return 'Thur'
      case 5:
        return 'Fri'
      case 6:
        return 'Sat'
      default:
        return ''
    }
  }
}
export default i18N
