// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/planeTicket',
  component: Start,
  children: [
    {
      path: 'query',
      name: '国内机票查询',
      component: resolve =>
        require(['pages/plane-ticket/book/query/query.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'inforList',
      name: '国内机票航班列表',
      redirect: '/home',
      component: resolve =>
        require(['pages/plane-ticket/book/infor-list/infor-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'bookDetails',
      name: '国内机票填写页面',
      redirect: '/home',
      component: resolve =>
        require(['pages/plane-ticket/book/book-details/book-details.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      // 国内机票订单列表
      name: 'planeTicketOrderList',
      path: 'orderList',
      redirect: '/home',
      component: resolve =>
        require(['pages/plane-ticket/order/order-list/order-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'orderConfirm',
      name: '国内机票订单确认',
      redirect: '/home',
      component: resolve =>
        require(['pages/plane-ticket/order/order-confirm/order-confirm.vue'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: 'orderDetails',
      name: '国内机票订单详情',
      component: resolve =>
        require(['pages/plane-ticket/order/order-details/order-details.vue'], resolve),
      meta: {
        keepAlive: false
      }
    },
    {
      path: 'toRefund',
      name: '国内机票去退票',
      redirect: '/home',
      component: resolve =>
        require(['pages/plane-ticket/refund/to-refund/to-refund.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'refundConfirm',
      name: '国内机票确认退票',
      redirect: '/home',
      component: resolve =>
        require(['pages/plane-ticket/refund/to-refund/refund-confirm.vue'], resolve)
    },
    {
      // 国内机票改期
      name: 'planeTicketToReschedule',
      path: 'toReschedule',
      redirect: '/home',
      component: resolve =>
        require(['pages/plane-ticket/reschedule/to-reschedule/to-reschedule.vue'], resolve)
    }
  ]
}
