import axios from 'axios';

const watchError = ({ appid, project }) => {
  /**
   * ***********************************************
   * error事件
   * ************************************************
   */
  const ERROR_EVENT = 'error';

  // 捕获同步和异步错误以及资源加载错误
  window.addEventListener(
    ERROR_EVENT,
    function (event) {
      // 事件触发时间
      if (event.target !== window) {
        // 资源加载错误
        sendErrorToServer({
          errorType: 'resource error',
          message: '有资源加载失败',
          source: event.target.outerHTML,
          lineno: -1,
          colno: -1,
          data: null
        });
      } else {
        // 其他错误事件
        sendErrorToServer({
          errorType: 'error',
          message: event.message,
          source: event.filename,
          lineno: event.lineno,
          colno: event.colno,
          data: event.error ? event.error.stack : null
        });
      }
    },
    true
  );

  /**
   * ***********************************************
   * 未处理promise rejection事件
   * ***********************************************
   */
  // const UNHANDLED_REJECTION_EVENT = 'unhandledrejection';

  // // 捕获未处理的Promise拒绝
  // window.addEventListener(UNHANDLED_REJECTION_EVENT, function (event) {
  //   sendErrorToServer({
  //     errorType: UNHANDLED_REJECTION_EVENT,
  //     message: event.reason ? event.reason.message : '接口请求过程中发生错误',
  //     source: '',
  //     lineno: -1,
  //     colno: -1,
  //     data: event.reason ? event.reason.stack : null
  //   });
  // });

  /**
   * ***********************************************
   * 发送错误信息到后端
   * ***********************************************
   */
  function sendErrorToServer(error) {
    // 如果是资源加载错误，且是图片加载错误，不发送错误信息
    if (error.errorType === 'resource error' && error.source.indexOf('img') !== -1) {
      return;
    }

    const message = {
      currentUrl: window.location.href,
      errorType: error.errorType,
      message: error.message,
      source: error.source,
      lineno: error.lineno,
      colno: error.colno,
      data: error.data
    };

    // 入参结构
    const params = {
      // 项目id
      Appid: appid,

      // 错误等级: 0 Info 1 Debug 2 Warn 3 Error 4 Fatal
      Level: 3,

      // 当前项目
      Title: project,

      // 错误信息
      Message: JSON.stringify(message),

      // 发送时间
      Time: new Date()
    };

    try {
      axios
        .post('/sLogApi/log/slog', params)
        .then((res) => {
          console.log('错误信息发送成功', res);
        })
        .catch((err) => {
          console.log('错误信息发送失败', err);
        });
    } catch (error) {
      console.log('axios 报错', error);
    }
  }
};

/**
 * ***********************************************
 * 发送Vue错误信息到后端
 * ***********************************************
 */
const sendVueErrorToServer = ({ appid, title, message }) => {
  let messageStr = {
    ...message,
    err: message.err ? `${message.err}` : null,
    instance: null,
    currentUrl: window.location.href
  };
  messageStr = JSON.stringify(messageStr);

  const params = {
    Appid: appid,
    Level: 3,
    Title: title,
    Message: messageStr,
    Time: new Date()
  };
  try {
    axios
      .post('/sLogApi/log/slog', params)
      .then((res) => {
        console.log('vue 错误信息发送成功', res);
      })
      .catch((err) => {
        console.log('vue 错误信息发送失败', err);
      });
  } catch (error) {
    console.log('vue axios 报错', error);
  }
};

export { watchError, sendVueErrorToServer };
